import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Radio } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import { BudgetPerdioDisplay } from './utils/getCategoriesBreakdown';

const styles: Record<string, AppFelaStyle> = {
  radio: ({ theme }) => ({
    fontSize: theme.fontSizeBase,
    display: 'flex',
    width: '100%',
    borderRadius: '35px',
    border: `1px solid ${theme.primaryColor}`,
    '& .ant-radio-button-wrapper': {
      flex: '0 0 50%',
      borderRadius: '35px',
      textAlign: 'center',
      '& .ant-radio-button': {
        border: 'none',
      },
      '&:last-child': {
        border: 'none',
      },
      '&:first-child': {
        border: 'none',
      },
      '&::before': {
        content: 'none',
      },
      '&:not(.ant-radio-button-wrapper-checked) span': {
        color: theme.primaryColor,
      },
    },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within':
      {
        boxShadow: 'none',
      },
  }),
};

type Props = {
  value: BudgetPerdioDisplay;
  onChange: (newValue: BudgetPerdioDisplay) => void;
};

export default function WalletBudgetBreakdownDisplaySelector({
  value,
  onChange,
}: Props): JSX.Element {
  const options = Object.values(BudgetPerdioDisplay).map((display) => ({
    label: display,
    value: display,
  }));
  const { css } = useFela<AppFelaTheme>();

  return (
    <Radio.Group
      options={options}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      optionType="button"
      buttonStyle="solid"
      className={css(styles.radio)}
    />
  );
}
