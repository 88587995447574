import type { PropertyBannerCardFragment$key } from '../../__generated__/relay/PropertyBannerCardFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import PropertyHotDataCard from './PropertyHotDataCard';

const DEFAULT_IMAGE = '/images/property_banner.png';

type FelaProps = {
  imageUrl: string;
};

const styles: Record<string, AppFelaStyle<FelaProps>> = {
  card: ({ theme }) => ({
    height: theme.md ? '750px' : 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: theme.md ? 'row' : 'column',
    justifyContent: 'flex-end',
    borderRadius: theme.md ? '20px' : '10px',
    overflow: 'hidden',
  }),
  data: ({ theme }) => ({
    position: theme.md ? 'absolute' : 'relative',
    width: theme.md ? '70%' : '100%',
    height: theme.md ? '100%' : 'auto',
    top: theme.md ? '0' : '-70px',
    zIndex: 2,
    borderRadius: theme.md ? 'none' : '0px 0px 10px 10px',
    left: '0',
    padding: theme.md ? '50px 60px' : '80px 20px 40px',
    background: theme.md
      ? 'linear-gradient( 90deg, #f4f4f4 48.3%, rgba(244, 244, 244, 0.512833) 79.22%, rgba(244, 244, 244, 0) 97.25% )'
      : 'linear-gradient(0deg, rgb(245, 245, 245) 0%, rgba(245, 245, 245, 0.98) 95%, rgb(245 245 245 / 12%) 100%)',
  }),
  background: ({ theme, imageUrl }) => ({
    width: theme.md ? '70%' : '100%',
    height: theme.md ? '100%' : '200px',
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }),
};

const fragment = graphql`
  fragment PropertyBannerCardFragment on property_fractions {
    property {
      imageUrl: image_url
    }
    ...PropertyHotDataCardFragment
  }
`;

type Props = {
  fragmentRef: PropertyBannerCardFragment$key;
};

function PropertyBannerCard({ fragmentRef }: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const { property } = fraction;

  const { css } = useFela<AppFelaTheme, FelaProps>({
    imageUrl: property?.imageUrl?.toString() ?? DEFAULT_IMAGE,
  });

  return (
    <div className={css(styles.card)}>
      <div className={css(styles.background)} />
      <div className={css(styles.data)}>
        <PropertyHotDataCard fragmentRef={fraction} />
      </div>
    </div>
  );
}

export default PropertyBannerCard;
