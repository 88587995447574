import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Card } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';
import { Outlet } from 'react-router-dom';

import Flexbox from '../components/core/Flexbox';
import { AppFelaStyle, AppFelaTheme } from '../styles/AppThemeProvider';

const { Content } = Layout;

const styles: Record<string, AppFelaStyle> = {
  layout: {
    backgroundImage: `url(/images/background_login.jpg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  gradient: {
    background:
      'linear-gradient(179.95deg, #F4F4F4 0%, rgba(244, 244, 244, 0.96) 20%, rgba(244, 244, 244, 0.512833) 35%, rgba(244, 244, 244, 0) 50%)',
  },
  toKocomo: ({ theme }) => ({
    fontSize: theme.xs ? '17px' : '15px',
    color: '#1A1A1A',
    position: 'absolute',
    top: '20px',
    left: theme.xs ? '20px' : '50px',
    zIndex: 2,
    letterSpacing: '3px',
  }),
  centeredRow: ({ theme }) => ({
    minHeight: '100vh',
    paddingRight: theme.xs ? '8px' : '40px',
    paddingLeft: theme.xs ? '8px' : '40px',
  }),
  card: ({ theme }) => ({
    height: '430px',
    width: '415px',
    maxWidth: '100%',
    paddingTop: '25px',
    borderRadius: theme.xs ? '10px' : '20px',
  }),
  logoWrapper: {
    paddingTop: '20px',
    paddingBottom: '40px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  logo: {
    width: '100%',
    maxWidth: '300px',
  },
};

function AuthLayout(): JSX.Element {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Layout className={css(styles.layout)}>
      <Content className={css(styles.gradient)}>
        <a className={css(styles.toKocomo)} href="https://www.kocomo.com/">
          <ArrowLeftOutlined style={{ marginRight: '15px' }} />
          BACK TO KOCOMO
        </a>
        <Row
          justify="center"
          align="middle"
          className={css(styles.centeredRow)}
        >
          <Col>
            <Flexbox
              direction="column"
              justifyContent="center"
              alignItems="center"
              rowGap="40px"
            >
              <img
                src="/images/logo_dark_green.svg"
                className={css(styles.logo)}
                alt="Kocomo"
              />
              <Card className={css(styles.card)}>
                <Outlet />
              </Card>
            </Flexbox>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default AuthLayout;
