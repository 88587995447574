/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CalendarHighlightSectionFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CalendarBookingsCardFragment" | "useConciergeHrefFragment">;
    readonly " $refType": "CalendarHighlightSectionFragment";
};
export type CalendarHighlightSectionFragment$data = CalendarHighlightSectionFragment;
export type CalendarHighlightSectionFragment$key = {
    readonly " $data"?: CalendarHighlightSectionFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CalendarHighlightSectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CalendarHighlightSectionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CalendarBookingsCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConciergeHrefFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'b24c231c95ab0ec5dff844e8cb75fba8';
export default node;
