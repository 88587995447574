import { captureMessage } from '@sentry/react';
import { hotjar } from 'react-hotjar';

import observeUserSessionChanges from './observeUserSessionChanges';

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
const HOTJAR_CHANNEL = process.env.REACT_APP_HOTJAR_CHANNEL;

function detectSession() {
  observeUserSessionChanges((id) => {
    hotjar.identify('USER_ID', { userProperty: id });
  });
}

function initHotjar() {
  if (HOTJAR_ID == null || HOTJAR_CHANNEL == null) {
    captureMessage('No environment variables from hotjar');
    return;
  }
  hotjar.initialize(parseInt(HOTJAR_ID, 10), parseInt(HOTJAR_CHANNEL, 10));
  detectSession();
}

initHotjar();
