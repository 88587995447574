/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoggedInLayoutQueryVariables = {};
export type LoggedInLayoutQueryResponse = {
    readonly me: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly fractions: ReadonlyArray<{
                    readonly id: string;
                }>;
                readonly " $fragmentRefs": FragmentRefs<"UserPreferencesContextProviderFragment" | "LoggedInHeaderFragment">;
            };
        }>;
    };
};
export type LoggedInLayoutQuery = {
    readonly response: LoggedInLayoutQueryResponse;
    readonly variables: LoggedInLayoutQueryVariables;
};



/*
query LoggedInLayoutQuery {
  me: me_connection {
    edges {
      node {
        id
        fractions: property_fractions {
          id
        }
        ...UserPreferencesContextProviderFragment
        ...LoggedInHeaderFragment
      }
    }
  }
}

fragment LoggedInHeaderFragment on users {
  ...PageTabsFragment
  ...ProfileDropdownFragment
}

fragment PageTabsFragment on users {
  fractions: property_fractions {
    id
    property {
      name
      id
    }
  }
}

fragment ProfileDropdownFragment on users {
  firstName: first_name
  lastName: last_name
  fractions: property_fractions {
    id
    property {
      name
      id
    }
  }
}

fragment UserPreferencesContextProviderFragment on users {
  id
  userId: user_id
  fractions: property_fractions {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoggedInLayoutQuery",
    "selections": [
      {
        "alias": "me",
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "me_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": "fractions",
                    "args": null,
                    "concreteType": "property_fractions",
                    "kind": "LinkedField",
                    "name": "property_fractions",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserPreferencesContextProviderFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LoggedInHeaderFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoggedInLayoutQuery",
    "selections": [
      {
        "alias": "me",
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "me_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": "fractions",
                    "args": null,
                    "concreteType": "property_fractions",
                    "kind": "LinkedField",
                    "name": "property_fractions",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "properties",
                        "kind": "LinkedField",
                        "name": "property",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "userId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user_id",
                    "storageKey": null
                  },
                  {
                    "alias": "firstName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": "lastName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da5fbf111108ebb29a0f5fd93a8b33f1",
    "id": null,
    "metadata": {},
    "name": "LoggedInLayoutQuery",
    "operationKind": "query",
    "text": "query LoggedInLayoutQuery {\n  me: me_connection {\n    edges {\n      node {\n        id\n        fractions: property_fractions {\n          id\n        }\n        ...UserPreferencesContextProviderFragment\n        ...LoggedInHeaderFragment\n      }\n    }\n  }\n}\n\nfragment LoggedInHeaderFragment on users {\n  ...PageTabsFragment\n  ...ProfileDropdownFragment\n}\n\nfragment PageTabsFragment on users {\n  fractions: property_fractions {\n    id\n    property {\n      name\n      id\n    }\n  }\n}\n\nfragment ProfileDropdownFragment on users {\n  firstName: first_name\n  lastName: last_name\n  fractions: property_fractions {\n    id\n    property {\n      name\n      id\n    }\n  }\n}\n\nfragment UserPreferencesContextProviderFragment on users {\n  id\n  userId: user_id\n  fractions: property_fractions {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cb14b450527f759c757c132926f37e16';
export default node;
