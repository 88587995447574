/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type WalletQueryVariables = {
    id: string;
    budgetYear: number;
};
export type WalletQueryResponse = {
    readonly fraction: {
        readonly id: string;
        readonly __typename: string;
        readonly " $fragmentRefs": FragmentRefs<"WalletHighlightSectionFragment" | "WalletBudgetBreakdownFragment">;
    } | null;
};
export type WalletQuery = {
    readonly response: WalletQueryResponse;
    readonly variables: WalletQueryVariables;
};



/*
query WalletQuery(
  $id: ID!
  $budgetYear: Int!
) {
  fraction: node(id: $id) {
    id
    __typename
    ... on property_fractions {
      id
      ...WalletHighlightSectionFragment
      ...WalletBudgetBreakdownFragment
    }
  }
}

fragment WalletBudgetBreakdownFragment on property_fractions {
  fractionCount: fraction_count
  property {
    fractionCount: total_fraction_count
    budgets: annual_budgets(where: {year: {_eq: $budgetYear}}) {
      year
      totalAmount: total_amount
      budgetDetails: annual_budget_details {
        id
        title
        category
        amount
      }
      id
    }
    id
  }
}

fragment WalletHighlightSectionFragment on property_fractions {
  ...WalletUpcomingPaymentCardFragment
  ...useConciergeHrefFragment
}

fragment WalletUpcomingPaymentCardFragment on property_fractions {
  fractionCount: fraction_count
  monthlyFee: monthly_fee
  property {
    name
    fractionCount: total_fraction_count
    budgets: annual_budgets(where: {year: {_eq: $budgetYear}}) {
      totalAmount: total_amount
      id
    }
    id
  }
  ...useNextPaymentDateFragment
}

fragment useConciergeHrefFragment on property_fractions {
  property {
    conciergeWhatsapp: concierge_whatsapp
    id
  }
}

fragment useNextPaymentDateFragment on property_fractions {
  boughtOn: bought_on
  property {
    ownerPaymentDayOfMonth: owner_payments_day_of_month
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "budgetYear"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletQuery",
    "selections": [
      {
        "alias": "fraction",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WalletHighlightSectionFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WalletBudgetBreakdownFragment"
              }
            ],
            "type": "property_fractions",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletQuery",
    "selections": [
      {
        "alias": "fraction",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "fractionCount",
                "args": null,
                "kind": "ScalarField",
                "name": "fraction_count",
                "storageKey": null
              },
              {
                "alias": "monthlyFee",
                "args": null,
                "kind": "ScalarField",
                "name": "monthly_fee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "properties",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": "fractionCount",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total_fraction_count",
                    "storageKey": null
                  },
                  {
                    "alias": "budgets",
                    "args": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_eq",
                                "variableName": "budgetYear"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "year"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "where"
                      }
                    ],
                    "concreteType": "annual_budgets",
                    "kind": "LinkedField",
                    "name": "annual_budgets",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "totalAmount",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "total_amount",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "year",
                        "storageKey": null
                      },
                      {
                        "alias": "budgetDetails",
                        "args": null,
                        "concreteType": "annual_budget_details",
                        "kind": "LinkedField",
                        "name": "annual_budget_details",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "category",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": "ownerPaymentDayOfMonth",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "owner_payments_day_of_month",
                    "storageKey": null
                  },
                  {
                    "alias": "conciergeWhatsapp",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "concierge_whatsapp",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "boughtOn",
                "args": null,
                "kind": "ScalarField",
                "name": "bought_on",
                "storageKey": null
              }
            ],
            "type": "property_fractions",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "104b0ef802e525c3bd75a4881d4d2f51",
    "id": null,
    "metadata": {},
    "name": "WalletQuery",
    "operationKind": "query",
    "text": "query WalletQuery(\n  $id: ID!\n  $budgetYear: Int!\n) {\n  fraction: node(id: $id) {\n    id\n    __typename\n    ... on property_fractions {\n      id\n      ...WalletHighlightSectionFragment\n      ...WalletBudgetBreakdownFragment\n    }\n  }\n}\n\nfragment WalletBudgetBreakdownFragment on property_fractions {\n  fractionCount: fraction_count\n  property {\n    fractionCount: total_fraction_count\n    budgets: annual_budgets(where: {year: {_eq: $budgetYear}}) {\n      year\n      totalAmount: total_amount\n      budgetDetails: annual_budget_details {\n        id\n        title\n        category\n        amount\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment WalletHighlightSectionFragment on property_fractions {\n  ...WalletUpcomingPaymentCardFragment\n  ...useConciergeHrefFragment\n}\n\nfragment WalletUpcomingPaymentCardFragment on property_fractions {\n  fractionCount: fraction_count\n  monthlyFee: monthly_fee\n  property {\n    name\n    fractionCount: total_fraction_count\n    budgets: annual_budgets(where: {year: {_eq: $budgetYear}}) {\n      totalAmount: total_amount\n      id\n    }\n    id\n  }\n  ...useNextPaymentDateFragment\n}\n\nfragment useConciergeHrefFragment on property_fractions {\n  property {\n    conciergeWhatsapp: concierge_whatsapp\n    id\n  }\n}\n\nfragment useNextPaymentDateFragment on property_fractions {\n  boughtOn: bought_on\n  property {\n    ownerPaymentDayOfMonth: owner_payments_day_of_month\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6a73dc18ff1fafef83e391fe4560e729';
export default node;
