import type { LoggedInLayoutQuery } from '../__generated__/relay/LoggedInLayoutQuery.graphql';
import type { FelaStyle } from 'react-fela';

import { Card, Grid, Layout, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { Suspense } from 'react';
import { useFela } from 'react-fela';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Outlet } from 'react-router-dom';

import AccountErrorCard from '../components/AccountErrorCard';
import ErrorBoundary from '../components/ErrorBoundary';
import LoggedInHeader from '../components/navbar/LoggedInHeader';
import { UserPreferencesContextProvider } from '../contexts/UserPreferencesContext';
import LoadingLayout from './LoadingLayout';

const { useBreakpoint } = Grid;
const { Content } = Layout;
const { Link } = Typography;

type StyleProps = {
  xs: boolean;
};

const styles: Record<string, FelaStyle<{}, StyleProps>> = {
  layout: ({ xs }) => ({
    minHeight: '100vh',
    overflowX: xs ? 'hidden' : '',
  }),
  content: ({ xs }) => ({
    paddingRight: xs ? '8px' : '20px',
    paddingLeft: xs ? '8px' : '20px',
    paddingTop: '64px',
    paddingBottom: '64px',
  }),
};

const query = graphql`
  query LoggedInLayoutQuery {
    me: me_connection {
      edges {
        node {
          id
          fractions: property_fractions {
            id
          }
          ...UserPreferencesContextProviderFragment
          ...LoggedInHeaderFragment
        }
      }
    }
  }
`;

function LoggedInLayout(): JSX.Element {
  const { xs } = useBreakpoint();
  const { css } = useFela<{}, StyleProps>({ xs: xs === true });

  const data = useLazyLoadQuery<LoggedInLayoutQuery>(query, {});

  if (data.me.edges.length < 1) {
    return <AccountErrorCard />;
  }

  const user = data.me.edges[0].node;
  const isOwner = user.fractions != null && user.fractions.length > 0;

  return (
    <UserPreferencesContextProvider fragmentRef={user}>
      <Layout className={css(styles.layout)}>
        <LoggedInHeader fragmentRef={user} />
        <Content className={css(styles.content)}>
          {isOwner ? <Outlet /> : <NoKocomosCard />}
        </Content>
      </Layout>
    </UserPreferencesContextProvider>
  );
}

function NoKocomosCard(): JSX.Element {
  return (
    <Card title="No Kocomos found">
      <p>
        You don&apos;t have any kocomos under your name. Please visit{' '}
        <Link href="www.kocomo.com">kocomo.com</Link> to know more
      </p>
    </Card>
  );
}

function LoggedInLayoutWrapper(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingLayout />}>
        <LoggedInLayout />
      </Suspense>
    </ErrorBoundary>
  );
}

export default LoggedInLayoutWrapper;
