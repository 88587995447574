import type { PropertyHotDataAddressItemFragment$key } from '../../__generated__/relay/PropertyHotDataAddressItemFragment.graphql';

import { EnvironmentOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import ActionIconLink from '../ActionIconLink';
import PropertyHotDataItem from './PropertyHotDataItem';

const { Text } = Typography;

const fragment = graphql`
  fragment PropertyHotDataAddressItemFragment on property_fractions {
    property {
      address {
        street1: street_1
        street2: street_2
        dependentLocality: dependent_locality
        locality
        state
        country
        postalCode: postal_code
        lngLat: lng_lat
      }
    }
  }
`;

type Props = {
  fragmentRef: PropertyHotDataAddressItemFragment$key;
};

function PropertyHotDataAddressItem({
  fragmentRef,
}: Props): JSX.Element | null {
  const { property } = useFragment(fragment, fragmentRef);
  const { address } = property;

  if (address == null) {
    return null;
  }

  const addressText = [
    address.street1,
    address.street2,
    address.dependentLocality,
    address.locality,
    address.state,
    address.country,
    address.postalCode,
  ]
    .filter(Boolean)
    .join(', ');

  const googleParams =
    address.lngLat != null ? (address.lngLat as string) : addressText;
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    googleParams,
  )}`;

  return (
    <PropertyHotDataItem
      title="Address"
      Icon={EnvironmentOutlined}
      content={<Text>{addressText}</Text>}
      action={<ActionIconLink href={addressUrl} />}
    />
  );
}

export default PropertyHotDataAddressItem;
