/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfilePhoneFragment = {
    readonly id: string;
    readonly userId: string;
    readonly phone: string | null;
    readonly " $refType": "ProfilePhoneFragment";
};
export type ProfilePhoneFragment$data = ProfilePhoneFragment;
export type ProfilePhoneFragment$key = {
    readonly " $data"?: ProfilePhoneFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfilePhoneFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfilePhoneFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "userId",
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = 'bbdc89a8c04e662871031b9daa40c591';
export default node;
