import type { expense_categories_enum } from '../../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';

export const CATEGORY_COLORS: Record<expense_categories_enum, string> = {
  MAINTENANCE: '#2AE295',
  PROPERTY_MANAGEMENT: '#F0E7D2',
  REPAIRS_RESERVE: '#FAF7F0',
  HOA: '#D9D9D9',
  UTILITIES: '#414236',
  HOUSEKEEPING: '#909990',
  INSURANCE: '#DFEFEA',
  PROPERTY_TAXES: '#006450',
  '%future added value': 'white',
};

const getCategoryColor = (categoryEnum: expense_categories_enum): string =>
  CATEGORY_COLORS[categoryEnum] ?? 'white';

export default getCategoryColor;
