import type { AuthError } from 'firebase/auth';

import { Form, Input, Typography } from 'antd';
import { AuthErrorCodes } from 'firebase/auth';
import React, { useCallback, useState } from 'react';

import useGridSpacing from '../../hooks/useGridSpacing';
import useUpdatePassword from '../../hooks/useUpdatePassword';
import Flexbox from '../core/Flexbox';
import HighlightCard from '../HighlightCard';
import Labeled from '../Labeled';
import ProfileUpdatePasswordActions from './ProfileUpdatePasswordActions';

type FormItemRule = React.ComponentProps<typeof Form.Item>['rules'];
type FormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const { Text } = Typography;

const RULES: Record<string, FormItemRule> = {
  oldPassword: [
    { required: true, message: 'Please enter your current password' },
  ],
  newPassword: [{ required: true, message: 'Please enter your new password' }],
  newPasswordConfirmation: [
    { required: true, message: 'Please confirm your new password' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('newPassword') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('The two passwords that you entered do not match!'),
        );
      },
    }),
  ],
};

const getErrorMessage = (error: AuthError): string => {
  switch (error.code) {
    case AuthErrorCodes.USER_DELETED:
      return 'User not found';
    case AuthErrorCodes.INVALID_EMAIL:
      return 'Email address is not valid';
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'Wrong password';
    case AuthErrorCodes.WEAK_PASSWORD:
      return 'New password should be at least 6 characters';
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
    case AuthErrorCodes.USER_MISMATCH:
    case AuthErrorCodes.INVALID_IDP_RESPONSE:
    case AuthErrorCodes.INVALID_CODE:
    case AuthErrorCodes.INVALID_SESSION_INFO:
    default:
      return 'There was an error, please try again later.';
  }
};

function ProfileUpdatePasswordCard(): JSX.Element {
  const spacing = useGridSpacing();

  const [form] = Form.useForm();
  const [updatePassword, loading, error] = useUpdatePassword();
  const [success, setSuccess] = useState<boolean>(false);

  const formHandler = useCallback(
    async (values: FormValues) => {
      const { oldPassword, newPassword } = values;
      const succeded = await updatePassword(oldPassword, newPassword);
      setSuccess(succeded);
      if (succeded) {
        form.resetFields();
      }
    },
    [updatePassword, form],
  );

  return (
    <Form
      form={form}
      name="updatePassword"
      onFinish={formHandler}
      validateTrigger="onSubmit"
      onValuesChange={() => setSuccess(false)}
    >
      <Flexbox direction="column" alignItems="stretch" rowGap={spacing.rowGap}>
        <HighlightCard>
          <Labeled title="Current password">
            <Form.Item name="oldPassword" rules={RULES.oldPassword}>
              <Input.Password />
            </Form.Item>
          </Labeled>
          <Labeled title="New password">
            <Form.Item name="newPassword" rules={RULES.newPassword}>
              <Input.Password />
            </Form.Item>
          </Labeled>
          <Labeled title="Confirm new password">
            <Form.Item
              name="newPasswordConfirmation"
              rules={RULES.newPasswordConfirmation}
            >
              <Input.Password />
            </Form.Item>
          </Labeled>
          {error && (
            <Form.Item>
              <Text type="danger">{getErrorMessage(error)}</Text>
            </Form.Item>
          )}
          {success && (
            <Form.Item>
              <Text type="success">Password updated</Text>
            </Form.Item>
          )}
        </HighlightCard>
        <ProfileUpdatePasswordActions loading={loading} />
      </Flexbox>
    </Form>
  );
}

export default ProfileUpdatePasswordCard;
