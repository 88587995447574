import type { FelaStyle } from 'react-fela';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useMemo } from 'react';
import { ThemeProvider } from 'react-fela';

export type AppFelaTheme = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  primaryColor: string;
  alternativeColor: string;
  textColor: string;
  textSecondaryColor: string;
  ligthColor: string;
  fontSizeTitle1: string;
  fontSizeTitle2: string;
  fontSizeTitle3: string;
  fontSizeTitle4: string;
  fontSizeBase: string;
  fontSizeSmall: string;
};

export type AppFelaStyle<P = {}> = FelaStyle<AppFelaTheme, P>;

type Props = {
  children?: React.ReactNode;
};

export default function AppThemeProvider({ children }: Props) {
  const { xs, sm, md, lg } = useBreakpoint();

  const theme: AppFelaTheme = useMemo(
    () => ({
      xs: xs === true,
      sm: sm === true,
      md: md === true,
      lg: lg === true,
      primaryColor: '#006450',
      alternativeColor: '#DE531B',
      textColor: '#1A1A1A',
      textSecondaryColor: '#9A9B95',
      ligthColor: '#2AE295',
      fontSizeTitle1: '200%',
      fontSizeTitle2: '180%',
      fontSizeTitle3: '150%',
      fontSizeTitle4: '120%',
      fontSizeBase: '100%',
      fontSizeSmall: '80%',
    }),
    [xs, sm, md, lg],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
