/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type user_roles_enum = "ADMINISTRATOR" | "OWNER" | "%future added value";
export type ProfileInfoCardQueryVariables = {};
export type ProfileInfoCardQueryResponse = {
    readonly me: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly email: string;
                readonly roles: ReadonlyArray<{
                    readonly role: user_roles_enum;
                }>;
                readonly " $fragmentRefs": FragmentRefs<"ProfileAvatarFragment" | "ProfileFirstNameFragment" | "ProfileLastNameFragment" | "ProfilePhoneFragment">;
            };
        }>;
    };
};
export type ProfileInfoCardQuery = {
    readonly response: ProfileInfoCardQueryResponse;
    readonly variables: ProfileInfoCardQueryVariables;
};



/*
query ProfileInfoCardQuery {
  me: me_connection {
    edges {
      node {
        id
        email
        roles: user_roles {
          role: user_role
          id
        }
        ...ProfileAvatarFragment
        ...ProfileFirstNameFragment
        ...ProfileLastNameFragment
        ...ProfilePhoneFragment
      }
    }
  }
}

fragment ProfileAvatarFragment on users {
  id
  firstName: first_name
  lastName: last_name
}

fragment ProfileFirstNameFragment on users {
  id
  userId: user_id
  firstName: first_name
}

fragment ProfileLastNameFragment on users {
  id
  userId: user_id
  lastName: last_name
}

fragment ProfilePhoneFragment on users {
  id
  userId: user_id
  phone
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": "role",
  "args": null,
  "kind": "ScalarField",
  "name": "user_role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileInfoCardQuery",
    "selections": [
      {
        "alias": "me",
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "me_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": "roles",
                    "args": null,
                    "concreteType": "users_user_roles",
                    "kind": "LinkedField",
                    "name": "user_roles",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProfileAvatarFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProfileFirstNameFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProfileLastNameFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProfilePhoneFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileInfoCardQuery",
    "selections": [
      {
        "alias": "me",
        "args": null,
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "me_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": "roles",
                    "args": null,
                    "concreteType": "users_user_roles",
                    "kind": "LinkedField",
                    "name": "user_roles",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "firstName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": "lastName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  },
                  {
                    "alias": "userId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f38fd64582cc0ec2948af5832260cb9",
    "id": null,
    "metadata": {},
    "name": "ProfileInfoCardQuery",
    "operationKind": "query",
    "text": "query ProfileInfoCardQuery {\n  me: me_connection {\n    edges {\n      node {\n        id\n        email\n        roles: user_roles {\n          role: user_role\n          id\n        }\n        ...ProfileAvatarFragment\n        ...ProfileFirstNameFragment\n        ...ProfileLastNameFragment\n        ...ProfilePhoneFragment\n      }\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on users {\n  id\n  firstName: first_name\n  lastName: last_name\n}\n\nfragment ProfileFirstNameFragment on users {\n  id\n  userId: user_id\n  firstName: first_name\n}\n\nfragment ProfileLastNameFragment on users {\n  id\n  userId: user_id\n  lastName: last_name\n}\n\nfragment ProfilePhoneFragment on users {\n  id\n  userId: user_id\n  phone\n}\n"
  }
};
})();
(node as any).hash = '7a28fe42c3e91ed8c1f1ce90f0d9b88d';
export default node;
