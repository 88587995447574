import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import useAuthState from '../hooks/useAuthState';
import LoadingLayout from '../layouts/LoadingLayout';

function PublicContent(): JSX.Element {
  const [currentUser, loading] = useAuthState();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setAuthorized(false);
      return;
    }

    if (currentUser != null) {
      setAuthorized(false);
      navigate('/', {
        state: { from: location },
      });
      return;
    }

    setAuthorized(true);
  }, [loading, currentUser, location, navigate]);

  return authorized ? <Outlet /> : <LoadingLayout />;
}

export default PublicContent;
