import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Row } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const styles: Record<string, AppFelaStyle> = {
  image: ({ theme }) => ({
    width: theme.xs ? '160px' : '230px',
    marginTop: theme.xs ? '20px' : '60px',
  }),
};

function CalendarFooterImage(): JSX.Element {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Row justify="center">
      <img src="/images/surf_board.svg" className={css(styles.image)} alt="" />
    </Row>
  );
}

export default CalendarFooterImage;
