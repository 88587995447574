import type { useConciergeHrefFragment$key } from '../__generated__/relay/useConciergeHrefFragment.graphql';

import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay/hooks';

const fragment = graphql`
  fragment useConciergeHrefFragment on property_fractions {
    property {
      conciergeWhatsapp: concierge_whatsapp
    }
  }
`;

function useConciergeHref(fragmentRef: useConciergeHrefFragment$key) {
  const { property } = useFragment(fragment, fragmentRef);
  const { conciergeWhatsapp } = property;

  if (conciergeWhatsapp == null) {
    return null;
  }

  const numbersOnly = conciergeWhatsapp.toString().replace(/\D/g, '');
  return `https://wa.me/${numbersOnly}`;
}

export default useConciergeHref;
