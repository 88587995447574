import { captureException } from '@sentry/react';
import { notification } from 'antd';

const DEFAULT_TITLE = 'There was an error';
const DEFAULT_DESCRIPTION = 'Please try again later';

export default function useErrorHandler(
  title?: string,
  description?: string,
): (error: Error) => void {
  return (error: Error) => {
    captureException(error);
    notification.error({
      message: title ?? DEFAULT_TITLE,
      description: description ?? DEFAULT_DESCRIPTION,
    });
  };
}
