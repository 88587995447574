import type { ProfileFirstNameFragment$key } from '../../__generated__/relay/ProfileFirstNameFragment.graphql';
import type { ProfileFirstNameMutation } from '../../__generated__/relay/ProfileFirstNameMutation.graphql';

import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay/hooks';

import useErrorHandler from '../../hooks/useErrorHandler';

const { Text } = Typography;

const fragment = graphql`
  fragment ProfileFirstNameFragment on users {
    id
    userId: user_id
    firstName: first_name
  }
`;

const mutation = graphql`
  mutation ProfileFirstNameMutation($user_id: uuid!, $data: users_set_input) {
    update_users_by_pk(pk_columns: { user_id: $user_id }, _set: $data) {
      id
      first_name
    }
  }
`;

type Props = {
  fragmentRef: ProfileFirstNameFragment$key;
};

function ProfileFirstName({ fragmentRef }: Props): JSX.Element {
  const { id, userId, firstName } = useFragment(fragment, fragmentRef);
  const onError = useErrorHandler('Error updating first name');

  const [commit] = useMutation<ProfileFirstNameMutation>(mutation);
  const onChange = useCallback(
    (newFirstName) => {
      commit({
        variables: {
          user_id: userId,
          data: { first_name: newFirstName },
        },
        optimisticResponse: {
          update_users_by_pk: {
            id,
            first_name: newFirstName,
          },
        },
        onError,
      });
    },
    [commit, onError, userId, id],
  );

  return <Text editable={{ onChange }}>{firstName}</Text>;
}

export default ProfileFirstName;
