import type { PropertyHotDataConciergeItemFragment$key } from '../../__generated__/relay/PropertyHotDataConciergeItemFragment.graphql';

import { WhatsAppOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import isEmpty from '../../utils/isEmpty';
import useConciergeHref from '../../utils/useConciergeHref';
import ActionIconLink from '../ActionIconLink';
import PropertyHotDataItem from './PropertyHotDataItem';

const { Text } = Typography;

const fragment = graphql`
  fragment PropertyHotDataConciergeItemFragment on property_fractions {
    property {
      conciergeName: concierge_name
      conciergeWhatsapp: concierge_whatsapp
    }
    ...useConciergeHrefFragment
  }
`;

type Props = {
  fragmentRef: PropertyHotDataConciergeItemFragment$key;
};

function PropertyHotDataConciergeItem({
  fragmentRef,
}: Props): JSX.Element | null {
  const fraction = useFragment(fragment, fragmentRef);
  const conciergeHref = useConciergeHref(fraction);
  const { property } = fraction;
  const conciergeName = property.conciergeName as unknown as string;
  const conciergeWhatsapp = property.conciergeWhatsapp as unknown as string;

  if (isEmpty(conciergeWhatsapp) && isEmpty(conciergeName)) {
    return null;
  }

  return (
    <PropertyHotDataItem
      title="Need support?"
      Icon={WhatsAppOutlined}
      content={
        <div>
          <Text>
            <Text type="secondary">Your Concierge:</Text> {conciergeName}
          </Text>
          <br />
          <Text>
            <Text type="secondary">Phone/WhatsApp:</Text> {conciergeWhatsapp}
          </Text>
        </div>
      }
      action={conciergeHref && <ActionIconLink href={conciergeHref} />}
    />
  );
}

export default PropertyHotDataConciergeItem;
