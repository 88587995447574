import type { AppFelaStyle, AppFelaTheme } from '../styles/AppThemeProvider';

import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from './core/Flexbox';

const styles: Record<string, AppFelaStyle> = {
  card: ({ theme }) => ({
    display: 'block',
    backgroundColor: '#FFF',
    padding: theme.xs ? '16px' : '28px 38px',
    borderRadius: theme.xs ? '16px' : '30px',
    overflow: 'hidden',
    width: '100%',
  }),
};

export default function HighlightCard(props: { children: React.ReactNode }) {
  const { children } = props;
  const { css } = useFela<AppFelaTheme>();

  return <Flexbox className={css(styles.card)}>{children}</Flexbox>;
}
