import { expense_categories_enum } from '../../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';
import toTitleCase from '../../../utils/toTitleCase';

const CATEGORY_MAPPING: Record<expense_categories_enum, string> = {
  INSURANCE: 'Insurance',
  MAINTENANCE: 'Maintenance',
  REPAIRS_RESERVE: 'Repairs Reserve',
  PROPERTY_MANAGEMENT: 'Property Management',
  PROPERTY_TAXES: 'Property Taxes',
  UTILITIES: 'Utilities',
  HOUSEKEEPING: 'Housekeeping',
  HOA: 'HOA',
  '%future added value': '',
};

const getCategoryTitle = (categoryEnum: expense_categories_enum): string =>
  CATEGORY_MAPPING[categoryEnum] ??
  toTitleCase(categoryEnum.replaceAll('_', ' '));

export default getCategoryTitle;
