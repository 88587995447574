import React from 'react';

import Button from '../../components/core/Button';
import Flexbox from '../../components/core/Flexbox';
import HighlightSection from '../../components/HighlightSection';
import ProfileFooterImage from '../../components/profile/ProfileFooterImage';
import ProfileInfoCard from '../../components/profile/ProfileInfoCard';
import SectionHeader from '../../components/SectionHeader';
import useGridSpacing from '../../hooks/useGridSpacing';

export default function Profile() {
  const spacing = useGridSpacing();

  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <HighlightSection>
        <Flexbox
          direction="column"
          alignItems="stretch"
          rowGap={spacing.rowGap}
        >
          <Flexbox direction="column">
            <SectionHeader title="Account Information" />
            <ProfileInfoCard />
          </Flexbox>
          <Flexbox justifyContent="center">
            <Button href="/profile/change_password">Change Password</Button>
          </Flexbox>
        </Flexbox>
      </HighlightSection>
      <ProfileFooterImage />
    </Flexbox>
  );
}
