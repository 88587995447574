import type { CalendarYearBookingsFragment$key } from '../../__generated__/relay/CalendarYearBookingsFragment.graphql';
import type { Booking } from './CalendarYearView';

import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useFragment } from 'react-relay/hooks';

import PageBody from '../PageBody';
import { BookingType } from './CalendarBookingTypeCheckboxGroup';
import CalendarYearView, { dateKey } from './CalendarYearView';

const fragment = graphql`
  fragment CalendarYearBookingsFragment on property_fractions {
    bookings(order_by: { date_start: asc }) {
      id
      dateStart: date_start
      dateEnd: date_end
      weeksCount: weeks_count
      bookingType: booking_type
    }
  }
`;

type Props = {
  filterBookingTypes: BookingType[];
  fragmentRef: CalendarYearBookingsFragment$key;
};

function CalendarYearBookings({ filterBookingTypes, fragmentRef }: Props) {
  const { bookings } = useFragment(fragment, fragmentRef);

  const bookingsMap = useMemo(() => {
    const map = new Map<string, Booking>();
    bookings
      .filter((booking) =>
        filterBookingTypes.includes(booking.bookingType as BookingType),
      )
      .forEach((booking) => {
        const start = dayjs(booking.dateStart);
        const end = dayjs(booking.dateEnd);
        const bookingType =
          booking.bookingType === 'RENTAL' ? 'RENTAL' : 'STAY';
        for (let date = start; !date.isAfter(end); date = date.add(1, 'day')) {
          map.set(dateKey(date), {
            bookingType,
            start,
            end,
          });
        }
      });
    return map;
  }, [bookings, filterBookingTypes]);

  return (
    <PageBody>
      <CalendarYearView bookings={bookingsMap} />
    </PageBody>
  );
}

export default CalendarYearBookings;
