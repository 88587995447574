import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import AntIcon from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from '../core/Flexbox';

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  icon: ({ theme }) => ({
    marginRight: theme.xs ? '8px' : '15px',
    fontSize: '18px',
  }),
  text: ({ theme }) => ({
    textAlign: 'justify',
    fontSize: theme.xs ? '14px' : '16px',
  }),
  iconAction: ({ theme }) => ({
    marginLeft: theme.xs ? '10px' : '10px',
    marginRight: theme.xs ? '-4px' : '0px',
  }),
  titleContainer: {
    marginBottom: '10px',
  },
  flexComplete: {
    width: '100%',
  },
};

type PropertyHotDataItemProps = Readonly<{
  Icon: typeof AntIcon;
  content: React.ReactNode;
  action: React.ReactNode;
  title: React.ReactNode;
}>;

function PropertyHotDataItem({
  title,
  Icon,
  content,
  action,
}: PropertyHotDataItemProps) {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Flexbox grow={1} direction="column" alignItems="start">
      <Flexbox
        className={css(styles.titleContainer)}
        grow={1}
        direction="row"
        alignItems="center"
      >
        <Icon className={css(styles.icon)} />
        <Text>{title}</Text>
      </Flexbox>
      <Flexbox
        className={css(styles.flexComplete)}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flexbox className={css(styles.text)} grow={1}>
          {content}
        </Flexbox>
        <Flexbox className={css(styles.iconAction)}>{action}</Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

export default PropertyHotDataItem;
