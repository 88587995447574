import type { PropertyUpcomingStayCardFragment$key } from '../../__generated__/relay/PropertyUpcomingStayCardFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import useConciergeHref from '../../utils/useConciergeHref';
import Button from '../core/Button';
import Flexbox from '../core/Flexbox';

dayjs.extend(advancedFormat);

const styles: Record<string, AppFelaStyle> = {
  image: {
    height: '60px',
  },
  text: ({ theme }) => ({
    fontSize: theme.xs ? theme.fontSizeTitle2 : theme.fontSizeTitle1,
  }),
  textNextStay: ({ theme }) => ({
    color: theme.primaryColor,
    fontSize: theme.xs ? theme.fontSizeTitle2 : theme.fontSizeTitle1,
  }),
  textArrive: ({ theme }) => ({
    fontSize: theme.fontSizeBase,
  }),
};

const { Text } = Typography;

const fragment = graphql`
  fragment PropertyUpcomingStayCardFragment on property_fractions {
    property {
      name
      conciergeWhatsapp: concierge_whatsapp
    }
    bookings(
      where: {
        date_start: { _gte: $today }
        _or: [
          { booking_type: { _eq: STAY } }
          { booking_type: { _eq: RENTAL } }
        ]
      }
      limit: 1
      order_by: { date_start: asc }
    ) {
      id
      dateStart: date_start
      dateEnd: date_end
      bookingType: booking_type
    }
    ...useConciergeHrefFragment
  }
`;

type Props = {
  fragmentRef: PropertyUpcomingStayCardFragment$key;
};

function PropertyUpcomingStayCard({ fragmentRef }: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const { property, bookings } = fraction;
  const conciergeHref = useConciergeHref(fraction);
  const { css } = useFela<AppFelaTheme>();

  const upcomingBooking = bookings[0];

  let nextStayDescription;
  if (upcomingBooking != null) {
    const dateStartStr = dayjs(upcomingBooking.dateStart).format(
      'MMM. DD, YYYY',
    );
    const dateEndStr = dayjs(upcomingBooking.dateEnd).format('MMM. DD, YYYY');
    nextStayDescription = `${dateStartStr} - ${dateEndStr}`;
  }

  return (
    <>
      {nextStayDescription ? (
        <Flexbox direction="column" alignItems="start">
          <img
            className={css(styles.image)}
            src="/images/luggages.svg"
            alt="luggages"
          />
          <Text type="secondary" className={css(styles.text)}>
            Upcoming {upcomingBooking.bookingType.toLowerCase()}
          </Text>
          <Text className={css(styles.textNextStay)}>
            {nextStayDescription}
          </Text>
          {upcomingBooking.bookingType !== 'RENTAL' && (
            <Text className={css(styles.textArrive)}>
              <Text type="secondary">Arrive</Text> 3pm ·{' '}
              <Text type="secondary">Depart</Text> 11am
            </Text>
          )}
        </Flexbox>
      ) : (
        <Flexbox direction="column" alignItems="start">
          <img
            className={css(styles.image)}
            src="/images/calendar.svg"
            alt="calendar"
          />
          <Text type="secondary" className={css(styles.text)}>
            Book your next stay at
          </Text>
          <Text className={css(styles.text)}>{property.name}</Text>
          {conciergeHref && (
            <Flexbox justifyContent="center">
              <Button type="primary" href={conciergeHref}>
                Contact Concierge
              </Button>
            </Flexbox>
          )}
        </Flexbox>
      )}
    </>
  );
}

export default PropertyUpcomingStayCard;
