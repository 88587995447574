import React, { Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import AuthLayout from './layouts/AuthLayout';
import LoadingLayout from './layouts/LoadingLayout';
import LoggedInLayout from './layouts/LoggedInLayout';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import LoginEmailPasswordPage from './pages/auth/LoginEmailPasswordPage';
import Calendar from './pages/Calendar';
import Property from './pages/MyHome';
import PageNotFound from './pages/PageNotFound';
import ChangePassword from './pages/profile/ChangePassword';
import Profile from './pages/profile/Profile';
import Finances from './pages/Wallet';
import usePageTitleUpdater from './utils/usePageTitleUpdater';

function App() {
  usePageTitleUpdater();
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingLayout />}>
        <Routes>
          <Route path="/*" element={<PrivateRoute />}>
            <Route element={<LoggedInLayout />}>
              <Route path="profile" element={<Profile />} />
              <Route
                path="profile/change_password"
                element={<ChangePassword />}
              />
              <Route path="wallet" element={<Finances />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="myhome" element={<Property />} />
              <Route index element={<Navigate replace to="myhome" />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
          <Route path="/auth" element={<PublicRoute />}>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<LoginEmailPasswordPage />} />
              <Route path="forgot" element={<ForgotPasswordPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
