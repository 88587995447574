import type { LoggedInHeaderFragment$key } from '../../__generated__/relay/LoggedInHeaderFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Col, Layout, Row } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import PageTabs from './PageTabs';
import ProfileDropdown from './ProfileDropdown';

const { Header } = Layout;

const styles: Record<string, AppFelaStyle> = {
  header: ({ theme }) => ({
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 10,
    paddingLeft: !theme.md ? '5%' : 'inherited',
    paddingRight: !theme.md ? '5%' : 'inherited',
  }),
  subMenu: ({ theme }) => ({
    lineHeight: !theme.md ? '32px' : 'inherited',
  }),
  rightHeaderMenus: {
    textAlign: 'right',
  },
  logo: {
    height: '20px',
  },
  bottomTabs: {
    backgroundColor: '#fff',
    zIndex: 10,
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    right: '0px',
    boxShadow: '0px 0px 8px #cbcbcb',
  },
  pointer: {
    cursor: 'pointer',
  },
};

const fragment = graphql`
  fragment LoggedInHeaderFragment on users {
    ...PageTabsFragment
    ...ProfileDropdownFragment
  }
`;

type Props = {
  fragmentRef: LoggedInHeaderFragment$key;
};

function LoggedInHeader({ fragmentRef }: Props): JSX.Element {
  const { css, theme } = useFela<AppFelaTheme>();
  const user = useFragment(fragment, fragmentRef);

  return (
    <>
      <Header className={css(styles.header)}>
        <Row wrap={false}>
          <Col flex="1">{theme.md && <PageTabs fragmentRef={user} />}</Col>
          <Col flex="0 1 50px" />
          <Col>
            <img
              src="/images/logo_dark_green.svg"
              className={css(styles.logo)}
              alt="Kocomo"
            />
          </Col>
          <Col flex="0 1 50px" />
          <Col flex="1" className={css(styles.rightHeaderMenus)}>
            <ProfileDropdown fragmentRef={user} />
          </Col>
        </Row>
      </Header>
      {!theme.md && (
        <Row className={css(styles.bottomTabs)} justify="center">
          <Col span={24}>
            <PageTabs fragmentRef={user} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default LoggedInHeader;
