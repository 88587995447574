import type { FelaStyle } from 'react-fela';

import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from './core/Flexbox';

const { Link, Text } = Typography;

const styles: Record<string, FelaStyle<{}>> = {
  centeredRow: {
    marginTop: '20%',
  },
  image: {
    width: '100%',
  },
  footer: {
    fontSize: '14px',
  },
  button: {
    marginTop: '20px',
  },
};

export default function ErrorFallback() {
  const { css } = useFela();

  return (
    <Row
      gutter={[70, 70]}
      justify="center"
      align="middle"
      className={css(styles.centeredRow)}
    >
      <Col>
        <Row justify="center">
          <Col>
            <img
              src="/images/surf_boards.svg"
              className={css(styles.image)}
              alt=""
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Flexbox direction="column" rowGap="5px" alignContent="center">
              <Text>Oops! Something went wrong.</Text>
              <Text type="secondary" className={css(styles.footer)}>
                Try again later or{' '}
                <Link href="mailto:info@kocomo.com">contact us</Link>
              </Text>
            </Flexbox>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
