import type { PageTabsFragment$key } from '../../__generated__/relay/PageTabsFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Menu, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';
import { Link, useLocation } from 'react-router-dom';

import {
  CalendarOutlined,
  CalendarFilled,
  HomeOutlined,
  HomeFilled,
  WalletOutlined,
  WalletFilled,
} from '../../assets/icons';
import { useActivePropertyFractionId } from '../../contexts/UserPreferencesContext';
import orEmptyArray from '../../utils/orEmptyArray';
import Flexbox from '../core/Flexbox';

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  tabs: ({ theme }) => ({
    borderBottom: 'none',
    marginBottom: 'none',
    whiteSpace: 'nowrap',
    '> .ant-menu-item': {
      '&:first-child': {
        paddingLeft: theme.md ? '0px' : '20px',
      },
      '::after': {
        borderBottom: 'none !important',
      },
    },
    lineHeight: theme.md ? 'inherit' : '100%',
  }),
  tabsPhone: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemContent: ({ theme }) => ({
    height: theme.md ? 'inherit' : '56px',
  }),
  text: ({ theme }) => ({
    color: 'inherit',
    fontSize: theme.xs ? '80%' : '87%',
    textTransform: theme.md ? 'uppercase' : 'none',
    letterSpacing: theme.md ? '3px' : 'inherit',
    fontWeight: '400',
  }),
  logo: {
    fontSize: '24px',
    color: 'inherit',
  },
};

const fragment = graphql`
  fragment PageTabsFragment on users {
    fractions: property_fractions {
      id
      property {
        name
      }
    }
  }
`;

type Props = {
  fragmentRef: PageTabsFragment$key;
};

function PageTabs({ fragmentRef }: Props): JSX.Element {
  const { css, theme } = useFela<AppFelaTheme>();
  const user = useFragment(fragment, fragmentRef);
  const fractions = orEmptyArray(user.fractions);
  const selectedFractionId = useActivePropertyFractionId();
  const selectedFraction = fractions.find(
    (fraction) => fraction.id === selectedFractionId,
  );

  const location = useLocation();
  const current = location.pathname.replace('/', '');

  const tabs = [
    {
      url: 'myhome',
      name: selectedFraction?.property.name,
      Icon: HomeOutlined,
      IconFilled: HomeFilled,
    },
    {
      url: 'calendar',
      name: 'Calendar',
      Icon: CalendarOutlined,
      IconFilled: CalendarFilled,
    },
    {
      url: 'wallet',
      name: 'Wallet',
      Icon: WalletOutlined,
      IconFilled: WalletFilled,
    },
  ];
  return (
    <Menu
      mode="horizontal"
      disabledOverflow
      selectedKeys={[current]}
      className={css(styles.tabs, theme.md ? {} : styles.tabsPhone)}
    >
      {tabs.map((tab) => {
        const selected = current === tab.url;
        const Icon = selected ? tab.IconFilled : tab.Icon;
        return (
          <Menu.Item key={tab.url}>
            <Link to={tab.url}>
              <Flexbox
                direction="column"
                rowGap="3px"
                alignItems="center"
                justifyContent="center"
                className={css(styles.itemContent)}
              >
                {!theme.md && (
                  <Icon
                    className={css(
                      styles.logo,
                      selected ? styles.logoSelected : {},
                    )}
                  />
                )}
                <Text className={css(styles.text)}>{tab.name}</Text>
              </Flexbox>
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default PageTabs;
