import type { AppFelaStyle, AppFelaTheme } from '../styles/AppThemeProvider';

import { SelectOutlined } from '@ant-design/icons';
import React from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';

const styles: Record<string, AppFelaStyle> = {
  hrefIcon: ({ theme }) => ({
    color: theme.primaryColor,
    height: '20px',
    width: '20px',
    fontSize: '20px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  }),
};

type Props = Readonly<{
  href: string;
}>;

function InfoCard({ href }: Props): JSX.Element {
  const { css } = useFela<AppFelaTheme>();
  const icon = <SelectOutlined className={css(styles.hrefIcon)} />;

  return href.startsWith('http') ? (
    <a href={href} target="_blank" rel="noreferrer">
      {icon}
    </a>
  ) : (
    <Link to={href}>{icon}</Link>
  );
}

export default InfoCard;
