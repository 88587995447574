import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useFela } from 'react-fela';

import Button from '../core/Button';
import Flexbox from '../core/Flexbox';
import { BookingType } from './CalendarBookingTypeCheckboxGroup';
import Calendar from './DayjsCalendar';

const { Paragraph, Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  yearSelector: ({ theme }) => ({
    fontSize: '200%',
    color: theme.primaryColor,
  }),
  monthHeader: ({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '3px',
    padding: '8px',
    fontSize: theme.xs ? theme.fontSizeSmall : theme.fontSizeBase,
  }),
  calendar: ({ theme }) => ({
    '& .ant-picker-cell-disabled::before': {
      background: 'inherit',
    },
    '& .ant-picker-content': {
      height: '205px',
    },
    '& .ant-picker-date-panel .ant-picker-content th': {
      fontSize: theme.fontSizeSmall,
      color: theme.textSecondaryColor,
    },
  }),
  dayContainer: ({ theme }) => ({
    width: '100%',
    marginBottom: '0px !important',
    fontSize: theme.fontSizeSmall,
    color: theme.textColor,
  }),
  bookingTypeStay: ({ theme }) => ({
    backgroundColor: `${theme.primaryColor}80`,
    '& .ant-typography': {
      color: '#FFF',
    },
  }),
  bookingTypeRental: ({ theme }) => ({
    backgroundColor: `${theme.alternativeColor}80`,
    '& .ant-typography': {
      color: '#FFF',
    },
  }),
  bookingTypeStayEvent: ({ theme }) => ({
    backgroundColor: theme.primaryColor,
  }),
  bookingTypeRentalEvent: ({ theme }) => ({
    backgroundColor: theme.alternativeColor,
  }),
  bookingStart: {
    borderRadius: '30px 0px 0px 30px',
  },
  bookingEnd: {
    borderRadius: '0px 30px 30px 0px',
  },
  today: ({ theme }) => ({
    backgroundColor: theme.ligthColor,
    borderRadius: '30px',
    color: '#FFF',
  }),
  before: {
    opacity: '0.5',
  },
  buttonNextPrev: {
    height: '44px !important',
  },
};

export type Booking = Readonly<{
  bookingType: BookingType;
  start: Dayjs;
  end: Dayjs;
}>;

type CalendarYearViewProps = Readonly<{
  bookings: ReadonlyMap<string, Booking>;
}>;

export const dateKey = (date: Dayjs) => date.format('YYYYMMDD');

const getBookingStyles = (
  date: Dayjs,
  bookings: ReadonlyMap<string, Booking>,
): Array<AppFelaStyle> => {
  const bookingStyles: Array<AppFelaStyle> = [];

  const booking = bookings.get(dateKey(date));

  if (booking != null) {
    bookingStyles.push(
      booking.bookingType === 'RENTAL'
        ? styles.bookingTypeRental
        : styles.bookingTypeStay,
    );
    if (date.isSame(booking.end) || date.isSame(booking.start)) {
      bookingStyles.push(
        booking.bookingType === 'RENTAL'
          ? styles.bookingTypeRentalEvent
          : styles.bookingTypeStayEvent,
      );

      if (date.isSame(booking.start)) {
        bookingStyles.push(styles.bookingStart);
      } else {
        bookingStyles.push(styles.bookingEnd);
      }
    }
  }

  return bookingStyles;
};

function CalendarYearView({ bookings = new Map() }: CalendarYearViewProps) {
  const { css } = useFela<AppFelaTheme>();
  const [year, setYear] = useState(new Date().getFullYear());
  const today = dayjs();

  return (
    <>
      <Flexbox
        columnGap="24px"
        justifyContent="center"
        alignItems="center"
        className={css(styles.yearContainer)}
      >
        <Tooltip title="Previous year">
          <Button
            className={css(styles.buttonNextPrev)}
            shape="circle"
            icon={<LeftOutlined />}
            onClick={() => setYear(year - 1)}
          />
        </Tooltip>
        <Text className={css(styles.yearSelector)}>{year}</Text>

        <Tooltip title="Next year">
          <Button
            className={css(styles.buttonNextPrev)}
            shape="circle"
            icon={<RightOutlined />}
            onClick={() => setYear(year + 1)}
          />
        </Tooltip>
      </Flexbox>
      <Row gutter={[40, 20]}>
        {[...Array(12)].map((_, i) => {
          const month = dayjs(new Date(year, i, 1));
          return (
            <Col key={month.format('YY/MMM')} xs={24} sm={12} lg={8} xxl={6}>
              <Calendar
                className={css(styles.calendar)}
                fullscreen={false}
                headerRender={() => (
                  <Flexbox>
                    <Text className={css(styles.monthHeader)}>
                      {month.format('MMM')}
                    </Text>
                  </Flexbox>
                )}
                value={month}
                disabledDate={() => true}
                dateFullCellRender={(date: Dayjs) =>
                  date.month() === month.month() ? (
                    <Paragraph
                      className={css(
                        styles.dayContainer,
                        ...getBookingStyles(date, bookings),
                      )}
                    >
                      <Paragraph
                        className={css(
                          date.isSame(today, 'day') ? styles.today : {},
                          date.isBefore(today, 'day') ? styles.before : {},
                        )}
                      >
                        {date.date()}
                      </Paragraph>
                    </Paragraph>
                  ) : null
                }
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default CalendarYearView;
