import type { expense_categories_enum } from '../../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';

const CATEGORY_MAPPING: Record<expense_categories_enum, string> = {
  MAINTENANCE: '#414236',
  PROPERTY_MANAGEMENT: '#414236',
  REPAIRS_RESERVE: '#414236',
  HOA: '#414236',
  UTILITIES: '#FFF',
  HOUSEKEEPING: '#FFF',
  INSURANCE: '#414236',
  PROPERTY_TAXES: '#FFF',
  '%future added value': '#414236',
};

const getCategoryFontColor = (categoryEnum: expense_categories_enum): string =>
  CATEGORY_MAPPING[categoryEnum] ?? 'white';

export default getCategoryFontColor;
