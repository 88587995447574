import React from 'react';

import Flexbox from '../../components/core/Flexbox';
import HighlightSection from '../../components/HighlightSection';
import ProfileFooterImage from '../../components/profile/ProfileFooterImage';
import ProfileUpdatePasswordCard from '../../components/profile/ProfileUpdatePasswordCard';
import SectionHeader from '../../components/SectionHeader';

export default function ChangePassword() {
  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <HighlightSection>
        <Flexbox direction="column">
          <SectionHeader title="Change Password" />
          <ProfileUpdatePasswordCard />
        </Flexbox>
      </HighlightSection>
      <ProfileFooterImage />
    </Flexbox>
  );
}
