import { signOut } from 'firebase/auth';
import { useCallback } from 'react';

import { useResetRelayEnvironment } from '../contexts/ResettableRelayEnvironmentContext';
import { auth } from '../utils/firebaseApp';

function useSignOut(): () => void {
  const resetRelayEnvironment = useResetRelayEnvironment();

  const signOutHandler = useCallback(async () => {
    await signOut(auth);
    resetRelayEnvironment();
  }, [resetRelayEnvironment]);

  return signOutHandler;
}

export default useSignOut;
