import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { ResettableRelayEnvironmentProvider } from './contexts/ResettableRelayEnvironmentContext';
import reportWebVitals from './reportWebVitals';
import Styles from './styles/Styles';
import createEnvironment from './utils/createRelayEnvironment';
import './utils/initHotjar';
import './utils/initSentry';

ReactDOM.render(
  <React.StrictMode>
    <Styles>
      <ResettableRelayEnvironmentProvider createEnvironment={createEnvironment}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ResettableRelayEnvironmentProvider>
    </Styles>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
