import type { FelaStyle } from 'react-fela';

import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from './Flexbox';

const styles: Record<string, FelaStyle<{}>> = {
  divider: {
    width: '100%',
    height: '0px',
    borderBottom: '1px solid rgba(26, 26, 26, 0.3)',
  },
};

type Props = Readonly<{
  className?: string;
}>;

function Divider({ className: classNameProp }: Props) {
  const { css } = useFela<{}>();

  const classNameCombined = [css(styles.divider), classNameProp]
    .filter(Boolean)
    .join(' ');

  return <Flexbox className={classNameCombined} />;
}

export default Divider;
