import type { expense_categories_enum } from '../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';
import type { AppFelaTheme } from '../../styles/AppThemeProvider';
import type { BudgetBreakdown } from './utils/getCategoriesBreakdown';
import type { ColumnsType } from 'antd/lib/table';
import type { FelaStyle } from 'react-fela';

import { Badge, Table, Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import formatCurrency from '../../utils/formatCurrency';
import getCategoryColor from './utils/getCategoryColor';
import getCategoryFontColor from './utils/getCategoryFontColor';
import getCategoryTitle from './utils/getCategoryTitle';

const { Text } = Typography;

const styles: Record<string, FelaStyle<AppFelaTheme>> = {
  table: {
    '& .ant-table': {
      background: 'transparent',
    },
    '& tr:last-child > td': {
      borderBottom: 'none',
    },
  },
};

const BUDGET_COLUMNS: ColumnsType<BudgetBreakdown[0]> = [
  {
    title: 'Percentage',
    width: '50px',
    dataIndex: 'percentage',
    render: (percentage: number, record: BudgetBreakdown[0]) => {
      const badgeColor = getCategoryColor(record.category);
      const textColor = getCategoryFontColor(record.category);
      return (
        <Badge
          style={{ backgroundColor: badgeColor, color: textColor }}
          count={`${Math.round(percentage)}%`}
        />
      );
    },
  },
  {
    title: 'Category',
    dataIndex: 'category',
    render: (category: expense_categories_enum) => (
      <Text>{getCategoryTitle(category)}</Text>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    align: 'right',
    render: (amount: number) => <Text>{formatCurrency(amount)}</Text>,
  },
];

type WalletBudgetBreakdownTableProps = {
  breakdown: BudgetBreakdown;
};

export default function WalletBudgetBreakdownTable({
  breakdown,
}: WalletBudgetBreakdownTableProps): JSX.Element {
  const { css } = useFela<AppFelaTheme>();
  return (
    <Table
      rowKey="category"
      pagination={false}
      showHeader={false}
      dataSource={breakdown}
      columns={BUDGET_COLUMNS}
      className={css(styles.table)}
    />
  );
}
