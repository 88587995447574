const assertInArrayOrFirst = <T>(
  element: T | null,
  validObjects: ReadonlyArray<T>,
): T | null => {
  if (element == null || !validObjects.includes(element)) {
    return validObjects[0];
  }
  return element;
};

export default assertInArrayOrFirst;
