/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserPreferencesContextProviderFragment = {
    readonly id: string;
    readonly userId: string;
    readonly fractions: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "UserPreferencesContextProviderFragment";
};
export type UserPreferencesContextProviderFragment$data = UserPreferencesContextProviderFragment;
export type UserPreferencesContextProviderFragment$key = {
    readonly " $data"?: UserPreferencesContextProviderFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserPreferencesContextProviderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPreferencesContextProviderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "userId",
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": "fractions",
      "args": null,
      "concreteType": "property_fractions",
      "kind": "LinkedField",
      "name": "property_fractions",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();
(node as any).hash = 'b1a88fb9b02f5795e1a501f59b76352a';
export default node;
