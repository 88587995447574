import React, { createContext, useContext, useMemo, useState } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { IEnvironment } from 'relay-runtime';

import emptyFunction from '../utils/emptyFunction';

type ResettableRelayEnvironmentProviderContextValue = {
  environment?: IEnvironment;
  resetRelayEnvironment: () => void;
};

const initial: ResettableRelayEnvironmentProviderContextValue = {
  resetRelayEnvironment: emptyFunction,
};

const ResettableRelayEnvironmentProviderContext =
  createContext<ResettableRelayEnvironmentProviderContextValue>(initial);

type Props = {
  children: React.ReactNode;
  createEnvironment: () => IEnvironment;
};

export function ResettableRelayEnvironmentProvider({
  children,
  createEnvironment,
}: Props) {
  const [environment, setEnvironment] = useState<IEnvironment>(
    createEnvironment(),
  );

  const contextValue = useMemo(
    () => ({
      environment,
      resetRelayEnvironment: () => setEnvironment(createEnvironment()),
    }),
    [environment, setEnvironment, createEnvironment],
  );

  return (
    <ResettableRelayEnvironmentProviderContext.Provider value={contextValue}>
      <RelayEnvironmentProvider environment={environment}>
        {children}
      </RelayEnvironmentProvider>
    </ResettableRelayEnvironmentProviderContext.Provider>
  );
}

export function useResetRelayEnvironment(): () => void {
  return useContext(ResettableRelayEnvironmentProviderContext)
    .resetRelayEnvironment;
}
