import { ErrorBoundary as ErrorBoundarySentry } from '@sentry/react';
import React from 'react';

import ErrorFallback from './ErrorFallback';

export default function ErrorBoundary(props: { children: React.ReactChild }) {
  const { children } = props;
  return (
    <ErrorBoundarySentry fallback={<ErrorFallback />}>
      {children}
    </ErrorBoundarySentry>
  );
}
