import type { AppFelaTheme } from '../styles/AppThemeProvider';
import type { FelaStyle } from 'react-fela';

import { Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const { Text } = Typography;

const styles: Record<string, FelaStyle<AppFelaTheme>> = {
  wrapper: ({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.xs ? '4px' : '16px',
  }),
  extraSpaceTop: ({ theme }) => ({
    marginTop: theme.xs ? '24px' : '48px',
  }),
  text: ({ theme }) => ({
    fontSize: theme.xs ? theme.fontSizeSmall : theme.fontSizeBase,
    textTransform: 'uppercase',
    letterSpacing: '3px',
    fontWeight: 400,
  }),
};

type Props = Readonly<{
  title: string;
  extraSpaceTop?: boolean;
}>;

export default function SectionHeader({ title, extraSpaceTop }: Props) {
  const { css } = useFela<AppFelaTheme>();

  return (
    <div
      className={css(
        styles.wrapper,
        extraSpaceTop === true ? styles.extraSpaceTop : {},
      )}
    >
      <Text className={css(styles.text)}>{title}</Text>
    </div>
  );
}
