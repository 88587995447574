import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';
import type { StyleFunction } from 'react-fela';

import { Checkbox, Row } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const coloredCheckboxStyle =
  (
    colorKey: 'primaryColor' | 'alternativeColor',
  ): StyleFunction<AppFelaTheme> =>
  ({ theme }) => ({
    '& .ant-checkbox': {
      top: theme.xs ? '0.2em' : '0',
    },
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: theme[colorKey],
      borderColor: theme[colorKey],
    },
    '&:hover .ant-checkbox-inner': {
      borderColor: theme[colorKey],
    },
    '& .ant-checkbox-input:focus + .ant-checkbox-inner': {
      borderColor: theme[colorKey],
    },
  });

const styles: Record<string, AppFelaStyle> = {
  bookingTypeSelector: ({ theme }) => ({
    marginBottom: '20px',
    fontSize: theme.xs ? theme.fontSizeBase : theme.fontSizeTitle3,
  }),
  optionStay: coloredCheckboxStyle('primaryColor'),
  optionRental: coloredCheckboxStyle('alternativeColor'),
};

export type BookingType = 'RENTAL' | 'STAY';

type BookingTypeCheckboxGroupProps = Readonly<{
  selectedValues: BookingType[];
  setSelectedValues: (newSelection: BookingType[]) => void;
}>;

function CalendarBookingTypeCheckboxGroup({
  selectedValues,
  setSelectedValues,
}: BookingTypeCheckboxGroupProps) {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Row justify="center">
      <Checkbox.Group
        value={selectedValues}
        onChange={(newSelection) =>
          setSelectedValues(newSelection as BookingType[])
        }
        className={css(styles.bookingTypeSelector)}
      >
        <Checkbox value="STAY" className={css(styles.optionStay)}>
          My Stays
        </Checkbox>
        <Checkbox value="RENTAL" className={css(styles.optionRental)}>
          My Rentals
        </Checkbox>
      </Checkbox.Group>
    </Row>
  );
}

export default CalendarBookingTypeCheckboxGroup;
