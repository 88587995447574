import type { FelaStyle } from 'react-fela';

import React from 'react';
import { useFela } from 'react-fela';

const styles: Record<string, FelaStyle<{}>> = {
  image: {
    width: '230px',
  },
};

function ProfileFooterImage(): JSX.Element {
  const { css } = useFela<{}>();

  return (
    <img
      src="/images/sand_castle.svg"
      className={css(styles.image)}
      alt="Sand Castle footer"
    />
  );
}

export default ProfileFooterImage;
