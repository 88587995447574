import type { ProfileDropdownFragment$key } from '../../__generated__/relay/ProfileDropdownFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import Icon, { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';
import { Link } from 'react-router-dom';

import { HomeFilled, HomeOutlined } from '../../assets/icons';
import {
  useActivePropertyFractionId,
  useSetActivePropertyFractionId,
} from '../../contexts/UserPreferencesContext';
import useSignOut from '../../hooks/useSignOut';
import orEmptyArray from '../../utils/orEmptyArray';

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  menu: ({ theme }) => ({
    background: theme.primaryColor,
    padding: '5px',
    top: '3px',
    '& .ant-dropdown-menu-item': {
      color: '#fff',
    },
    '& .ant-dropdown-menu-item:hover': {
      backgroundColor: 'transparent',
      color: theme.ligthColor,
    },
  }),
  pointer: {
    cursor: 'pointer',
  },
  itemText: {
    marginLeft: '6px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: 'inherit',
  },
  avatar: ({ theme }) => ({
    backgroundColor: theme.primaryColor,
    fontSize: '0.8em !important',
  }),
  divider: {
    opacity: '0.7',
  },
};

const fragment = graphql`
  fragment ProfileDropdownFragment on users {
    firstName: first_name
    lastName: last_name
    fractions: property_fractions {
      id
      property {
        name
      }
    }
  }
`;

const firstLetter = (text: string | null): string => (text ? text[0] : '');

type Props = {
  fragmentRef: ProfileDropdownFragment$key;
};

function ProfileDropdown({ fragmentRef }: Props): JSX.Element {
  const { css } = useFela<AppFelaTheme>();
  const { firstName, lastName, fractions } = useFragment(fragment, fragmentRef);
  const signOutHandler = useSignOut();

  const initials = `${firstLetter(firstName)}${firstLetter(lastName)}`;

  const selectedFractionId = useActivePropertyFractionId();
  const setActivePropertyFractionId = useSetActivePropertyFractionId();

  return (
    <Dropdown
      overlay={
        <Menu className={css(styles.menu)}>
          <Menu.Item>
            <Link to="profile">
              <ProfileOutlined />
              <Text className={css(styles.itemText)}>My account</Text>
            </Link>
          </Menu.Item>
          {orEmptyArray(fractions).length > 1 && (
            <>
              <Menu.Divider className={css(styles.divider)} />
              {fractions.map((fraction) => (
                <Menu.Item
                  key={fraction.id}
                  onClick={() => setActivePropertyFractionId(fraction.id)}
                >
                  {selectedFractionId === fraction.id ? (
                    <Icon component={HomeFilled} />
                  ) : (
                    <Icon component={HomeOutlined} />
                  )}
                  <Text className={css(styles.itemText)}>
                    {fraction.property.name}
                  </Text>
                </Menu.Item>
              ))}
            </>
          )}
          <Menu.Divider className={css(styles.divider)} />
          <Menu.Item onClick={signOutHandler}>
            <LogoutOutlined />
            <Text className={css(styles.itemText)}>Log out</Text>
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <Text className={css(styles.pointer)}>
        <Avatar size={30} className={css(styles.avatar)}>
          {initials}
        </Avatar>
      </Text>
    </Dropdown>
  );
}

export default ProfileDropdown;
