import type { ProfileLastNameFragment$key } from '../../__generated__/relay/ProfileLastNameFragment.graphql';
import type { ProfileLastNameMutation } from '../../__generated__/relay/ProfileLastNameMutation.graphql';

import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay/hooks';

import useErrorHandler from '../../hooks/useErrorHandler';

const { Text } = Typography;

const fragment = graphql`
  fragment ProfileLastNameFragment on users {
    id
    userId: user_id
    lastName: last_name
  }
`;

const mutation = graphql`
  mutation ProfileLastNameMutation($user_id: uuid!, $data: users_set_input) {
    update_users_by_pk(pk_columns: { user_id: $user_id }, _set: $data) {
      id
      last_name
    }
  }
`;

type Props = {
  fragmentRef: ProfileLastNameFragment$key;
};

function ProfileLastName({ fragmentRef }: Props): JSX.Element {
  const { id, userId, lastName } = useFragment(fragment, fragmentRef);
  const onError = useErrorHandler('Error updating last name');

  const [commit] = useMutation<ProfileLastNameMutation>(mutation);
  const onChange = useCallback(
    (newLastName) => {
      commit({
        variables: {
          user_id: userId,
          data: { last_name: newLastName },
        },
        optimisticResponse: {
          update_users_by_pk: {
            id,
            last_name: newLastName,
          },
        },
        onError,
      });
    },
    [commit, onError, userId, id],
  );

  return <Text editable={{ onChange }}>{lastName}</Text>;
}

export default ProfileLastName;
