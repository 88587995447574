import type { WalletUpcomingPaymentCardFragment$key } from '../../__generated__/relay/WalletUpcomingPaymentCardFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';
import type { Dayjs } from 'dayjs';

import { Skeleton, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import formatCurrency from '../../utils/formatCurrency';
import Divider from '../core/Divider';
import Flexbox from '../core/Flexbox';
import Spacer from '../core/Spacer';
import HighlightCard from '../HighlightCard';
import useNextPaymentDate from './utils/useNextPaymentDate';

dayjs.extend(advancedFormat);

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  image: ({ theme }) => ({
    width: theme.xs ? '55px' : '100px',
  }),
  textLarge: ({ theme }) => ({
    fontSize: theme.fontSizeTitle3,
  }),
  textBase: ({ theme }) => ({
    fontSize: theme.fontSizeBase,
  }),
  textSmall: ({ theme }) => ({
    fontSize: theme.fontSizeSmall,
  }),
  highlightText: ({ theme }) => ({
    color: theme.primaryColor,
    fontWeight: '200',
  }),
  boldText: ({ theme }) => ({
    color: theme.primaryColor,
    fontWeight: '400',
  }),
  breakdownHeader: ({ theme }) => ({
    fontSize: theme.xs ? theme.fontSizeSmall : theme.fontSizeBase,
    textTransform: 'uppercase',
    letterSpacing: '3px',
    fontWeight: 400,
  }),
};

const fragment = graphql`
  fragment WalletUpcomingPaymentCardFragment on property_fractions {
    fractionCount: fraction_count
    monthlyFee: monthly_fee
    property {
      name
      fractionCount: total_fraction_count
      budgets: annual_budgets(where: { year: { _eq: $budgetYear } }) {
        totalAmount: total_amount
      }
    }
    ...useNextPaymentDateFragment
  }
`;

type Props = {
  fragmentRef: WalletUpcomingPaymentCardFragment$key;
};

function WalletUpcomingPaymentCard({ fragmentRef }: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const dueDate = useNextPaymentDate(fraction);
  const { property, monthlyFee, fractionCount } = fraction;
  const totalBudgetAmount = property.budgets[0]?.totalAmount;

  let monthlyBudget;
  let dueAmount;

  if (totalBudgetAmount != null) {
    monthlyBudget =
      (totalBudgetAmount * fractionCount) / (property.fractionCount * 12);
    dueAmount = monthlyBudget + monthlyFee;
  }

  return (
    <HighlightCard>
      <WalletUpcomingPaymentCardDueSummary
        dueAmount={dueAmount}
        dueDate={dueDate}
      />
      <Spacer height="20px" />
      <Divider />
      <Spacer height="16px" />
      <WalletUpcomingPaymentCardBreakdown
        propertyName={property.name}
        monthlyBudget={monthlyBudget}
        monthlyFee={monthlyFee}
        propertyFractions={property.fractionCount}
        ownerFractions={fractionCount}
      />
    </HighlightCard>
  );
}

type DueSummaryProps = {
  dueAmount?: number;
  dueDate: Dayjs;
};

function WalletUpcomingPaymentCardDueSummary({
  dueAmount,
  dueDate,
}: DueSummaryProps) {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Flexbox justifyContent="space-between" alignItems="center">
      <Flexbox direction="column">
        <Text className={css(styles.textLarge)}>
          {dueAmount != null ? formatCurrency(dueAmount) : '-'}
        </Text>
        <Text className={css(styles.textBase, styles.highlightText)}>
          Due{' '}
          <Text className={css(styles.boldText)}>
            {dueDate.format('MMMM Do, YYYY')}
          </Text>
        </Text>
      </Flexbox>
      <Flexbox>
        <img src="/images/coins.svg" alt="" className={css(styles.image)} />
      </Flexbox>
    </Flexbox>
  );
}

type BreakdownProps = {
  propertyName: string;
  monthlyBudget?: number;
  monthlyFee?: number;
  propertyFractions: number;
  ownerFractions: number;
};

function WalletUpcomingPaymentCardBreakdown({
  propertyName,
  monthlyBudget,
  monthlyFee,
  propertyFractions,
  ownerFractions,
}: BreakdownProps) {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Flexbox
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Flexbox justifyContent="center">
        <Text type="secondary" className={css(styles.breakdownHeader)}>
          Monthly Breakdown
        </Text>
      </Flexbox>
      <Spacer height="20px" />
      <Flexbox grow={1} justifyContent="space-between">
        <Text type="secondary" className={css(styles.textBase)}>
          <Text className={css(styles.boldText)}>{propertyName}</Text> Budget
        </Text>
        <Text type="secondary" className={css(styles.textBase)}>
          {monthlyBudget != null ? formatCurrency(monthlyBudget) : '-'}
        </Text>
      </Flexbox>
      <Text
        type="secondary"
        className={css(styles.textSmall)}
      >{`${ownerFractions} of ${propertyFractions} shares`}</Text>
      <Spacer height="20px" />
      <Flexbox grow={1} justifyContent="space-between">
        <Text type="secondary" className={css(styles.textBase)}>
          Kocomo platform fee
        </Text>
        <Text type="secondary" className={css(styles.textBase)}>
          {monthlyFee != null ? formatCurrency(monthlyFee) : '-'}
        </Text>
      </Flexbox>
      <Spacer height="20px" />
    </Flexbox>
  );
}

export function WalletUpcomingPaymentCardLoading() {
  return (
    <HighlightCard>
      <Skeleton.Input active style={{ width: '200px' }} />
      <Spacer height="5px" />
      <Skeleton.Input active style={{ width: '150px' }} />
      <Spacer height="20px" />
      <Divider />
      <Spacer height="16px" />
      <Skeleton active title={false} paragraph={{ rows: 3 }} />
    </HighlightCard>
  );
}

export default WalletUpcomingPaymentCard;
