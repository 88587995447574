/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileDropdownFragment = {
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly fractions: ReadonlyArray<{
        readonly id: string;
        readonly property: {
            readonly name: string;
        };
    }>;
    readonly " $refType": "ProfileDropdownFragment";
};
export type ProfileDropdownFragment$data = ProfileDropdownFragment;
export type ProfileDropdownFragment$key = {
    readonly " $data"?: ProfileDropdownFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileDropdownFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileDropdownFragment",
  "selections": [
    {
      "alias": "firstName",
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": "lastName",
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": "fractions",
      "args": null,
      "concreteType": "property_fractions",
      "kind": "LinkedField",
      "name": "property_fractions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "properties",
          "kind": "LinkedField",
          "name": "property",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = 'd750bc2612432937f51ea5c9f61d2a9c';
export default node;
