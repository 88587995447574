import type { PropertyGuidelinesSectionFragment$key } from '../../__generated__/relay/PropertyGuidelinesSectionFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Affix, Col, Row, Tabs, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import Flexbox from '../core/Flexbox';
import Spacer from '../core/Spacer';
import PageBody from '../PageBody';
import PropertyGuidelinesCard from './PropertyGuidelinesCard';

const { Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const BAR_HEIGHT = 152;
const SCROLL_BUFFER = 5;

const styles: Record<string, AppFelaStyle> = {
  width100: {
    width: '100%',
  },
  categoryTitle: ({ theme }) => ({
    fontSize: theme.xs ? theme.fontSizeTitle4 : theme.fontSizeTitle2,
    padding: '12px 16px',
  }),
  tabsContainer: {
    textTransform: 'uppercase',
    backgroundColor: '#FFF',
    '& .ant-tabs-nav': {
      marginBottom: '0',
    },
    '& .ant-tabs-nav .ant-tabs-nav-wrap::after': {
      boxShadow: 'none',
    },
    '& .ant-tabs-nav .ant-tabs-nav-wrap::before': {
      boxShadow: 'none',
    },
  },
  title: ({ theme }) => ({
    fontSize: theme.fontSizeTitle1,
    color: theme.primaryColor,
  }),
  tab: {
    padding: '10px',
    fontSize: '0.8rem',
    letterSpacing: '1px',
  },
};

const fragment = graphql`
  fragment PropertyGuidelinesSectionFragment on property_fractions {
    property {
      categories: property_manual_categories(order_by: { order: asc }) {
        id
        title
        ...PropertyGuidelinesCardFragment
      }
    }
  }
`;

type Props = {
  fragmentRef: PropertyGuidelinesSectionFragment$key;
};

function PropertyGuidelinesSection({ fragmentRef }: Props): JSX.Element | null {
  const { css, theme } = useFela<AppFelaTheme>();
  const { property } = useFragment(fragment, fragmentRef);
  const categoriesRef = useRef<[HTMLDivElement | null]>([null]);
  const [activeGuideline, setActiveGuideline] = useState<string>('0');

  const handleTabChange = (indexTab: string) => {
    setActiveGuideline(indexTab);
    const index = Number.parseInt(indexTab, 10);
    const divFind = categoriesRef.current[index];
    if (divFind == null) {
      return;
    }
    const yOffset = -1 * BAR_HEIGHT;
    const y =
      divFind.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.pageYOffset + BAR_HEIGHT + SCROLL_BUFFER;
    let currentCategoryIndex = 0;
    for (let i = 0; i < categoriesRef.current.length; i += 1) {
      const element = categoriesRef.current[i];
      if (element != null) {
        if (element.offsetTop <= scrollPosition) {
          currentCategoryIndex = i;
        } else {
          break;
        }
      }
    }
    setActiveGuideline(currentCategoryIndex.toString());
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <PageBody>
      <Flexbox direction="column" alignContent="stretch">
        <Spacer height={theme.md ? '50px' : '0px'} />
        <Flexbox alignItems="center" justifyContent="center">
          <Text className={css(styles.title)}>Quickstart Guide</Text>
        </Flexbox>
        <Spacer height="20px" />
        <Flexbox>
          <Affix offsetTop={64} className={css(styles.width100)}>
            <Tabs
              className={css(styles.tabsContainer)}
              onChange={handleTabChange}
              activeKey={activeGuideline}
              centered
            >
              {property.categories.map((category, i) => (
                <TabPane
                  className={css(styles.tab)}
                  tab={category.title}
                  key={i.toString()}
                />
              ))}
            </Tabs>
          </Affix>
        </Flexbox>
        <Spacer height="30px" />
        <Flexbox>
          <Row className={css(styles.width100)} gutter={[0, 50]}>
            {property.categories.map((category, i) => (
              <Col
                key={category.id}
                xs={24}
                ref={(el) => {
                  categoriesRef.current[i] = el;
                }}
              >
                <Paragraph className={css(styles.categoryTitle)}>
                  {category.title}
                </Paragraph>
                <PropertyGuidelinesCard fragmentRef={category} />
              </Col>
            ))}
          </Row>
        </Flexbox>
      </Flexbox>
    </PageBody>
  );
}

export default PropertyGuidelinesSection;
