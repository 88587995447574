import type { LoadingHook } from './useLoadingValue';
import type { User } from 'firebase/auth';

import { useEffect, useMemo } from 'react';

import { auth } from '../utils/firebaseApp';
import useLoadingValue from './useLoadingValue';

export type AuthStateHook = LoadingHook<User | null, Error>;

const useAuthState = (): AuthStateHook => {
  const {
    value: currentUser,
    setValue: setCurrentUser,
    loading,
    error,
    setError,
  } = useLoadingValue<User | null, Error>(auth.currentUser);

  useEffect(() => {
    const listener = auth.onAuthStateChanged(setCurrentUser, setError);

    return () => {
      listener();
    };
  }, [setCurrentUser, setError]);

  return useMemo<AuthStateHook>(
    () => [currentUser, loading, error],
    [currentUser, loading, error],
  );
};

export default useAuthState;
