import type {
  booking_types_enum,
  CalendarBookingsTableFragment$data,
  CalendarBookingsTableFragment$key,
} from '../../__generated__/relay/CalendarBookingsTableFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';
import type { BookingType } from './CalendarBookingTypeCheckboxGroup';
import type { ColumnsType } from 'antd/lib/table';

import { Table, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

const { Text } = Typography;

type Booking = CalendarBookingsTableFragment$data['bookings'][0];

const styles: Record<string, AppFelaStyle> = {
  bookingTypeColumn: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  bookingType: {
    height: '37.4px',
    width: '5px',
    borderRadius: '30px',
  },
  bookingTypeRental: ({ theme }) => ({
    backgroundColor: theme.alternativeColor,
  }),
  bookingTypeStay: ({ theme }) => ({
    backgroundColor: theme.primaryColor,
  }),
  bookingPeriodPast: {
    opacity: '0.5',
  },
  table: {
    '& tr:last-child > td': {
      borderBottom: 'none',
    },
  },
};

const fragment = graphql`
  fragment CalendarBookingsTableFragment on property_fractions {
    bookings(order_by: { date_start: asc }) {
      id
      dateStart: date_start
      dateEnd: date_end
      weeksCount: weeks_count
      bookingType: booking_type
    }
  }
`;

type Props = {
  fragmentRef: CalendarBookingsTableFragment$key;
  selectedBookingTypes: BookingType[];
};

function CalendarBookingsTable({ fragmentRef, selectedBookingTypes }: Props) {
  const { bookings } = useFragment(fragment, fragmentRef);
  const { css } = useFela<AppFelaTheme>();

  const fileredBooking = useMemo(
    () =>
      bookings.filter((booking) =>
        selectedBookingTypes.includes(booking.bookingType as BookingType),
      ),
    [bookings, selectedBookingTypes],
  );

  if (selectedBookingTypes.length === 0) {
    return <Text>Please select an option</Text>;
  }

  if (fileredBooking.length === 0) {
    return <Text>No bookings found</Text>;
  }

  const columns: ColumnsType<Booking> = [
    {
      title: 'Booking Type',
      dataIndex: 'bookingType',
      className: css(styles.bookingTypeColumn),
      render: (bookingType: booking_types_enum, { dateEnd }: Booking) => {
        const bookingPeriodStyle =
          dayjs(dateEnd) < dayjs() ? styles.bookingPeriodPast : {};

        const bookingTypeStyle =
          bookingType === 'RENTAL'
            ? styles.bookingTypeRental
            : styles.bookingTypeStay;

        return (
          <div
            className={css(
              styles.bookingType,
              bookingTypeStyle,
              bookingPeriodStyle,
            )}
          />
        );
      },
    },
    {
      title: 'Dates',
      dataIndex: 'dateStart',
      render: (dateStart: string, { dateEnd }: Booking) => {
        const dates = `${dayjs(dateStart).format('MMM. DD')} - ${dayjs(
          dateEnd,
        ).format('MMM. DD, YYYY')}`;

        const bookingPeriodStyle =
          dayjs(dateEnd) < dayjs() ? styles.bookingPeriodPast : {};

        return <Text className={css(bookingPeriodStyle)}>{dates}</Text>;
      },
    },
    {
      title: 'Weeks',
      dataIndex: 'weeksCount',
      align: 'right',
      render: (weeksCount: number, { dateEnd }: Booking) => {
        const bookingPeriodStyle =
          dayjs(dateEnd) < dayjs() ? styles.bookingPeriodPast : {};

        return (
          <Text className={css(bookingPeriodStyle)}>
            {weeksCount} week{weeksCount > 1 && 's'}
          </Text>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      showHeader={false}
      pagination={false}
      dataSource={fileredBooking}
      columns={columns}
      className={css(styles.table)}
    />
  );
}

export default CalendarBookingsTable;
