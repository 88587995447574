import type { CalendarHighlightSectionFragment$key } from '../../__generated__/relay/CalendarHighlightSectionFragment.graphql';
import type { BookingType } from './CalendarBookingTypeCheckboxGroup';

import { Skeleton } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import useGridSpacing from '../../hooks/useGridSpacing';
import useConciergeHref from '../../utils/useConciergeHref';
import Button from '../core/Button';
import Flexbox from '../core/Flexbox';
import Spacer from '../core/Spacer';
import HighlightCard from '../HighlightCard';
import HighlightSection from '../HighlightSection';
import SectionHeader from '../SectionHeader';
import CalendarBookingsCard from './CalendarBookingsCard';

const fragment = graphql`
  fragment CalendarHighlightSectionFragment on property_fractions {
    ...CalendarBookingsCardFragment
    ...useConciergeHrefFragment
  }
`;

type Props = {
  fragmentRef: CalendarHighlightSectionFragment$key;
  selectedBookingTypes: BookingType[];
  setSelectedBookingTypes: (newSelection: BookingType[]) => void;
};

function CalendarHighlightSection({
  fragmentRef,
  selectedBookingTypes,
  setSelectedBookingTypes,
}: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const conciergeHref = useConciergeHref(fraction);
  const spacing = useGridSpacing();

  return (
    <HighlightSection>
      <Flexbox direction="column" alignItems="stretch" rowGap={spacing.rowGap}>
        <Flexbox direction="column">
          <SectionHeader title="My booked weeks" />
          <CalendarBookingsCard
            fragmentRef={fraction}
            selectedBookingTypes={selectedBookingTypes}
            setSelectedBookingTypes={setSelectedBookingTypes}
          />
        </Flexbox>
        {conciergeHref && (
          <Flexbox direction="column" alignItems="center">
            <SectionHeader title="Change of plans?" />
            <Button href={conciergeHref}>Request a change</Button>
          </Flexbox>
        )}
      </Flexbox>
    </HighlightSection>
  );
}

export function CalendarHighlightSectionLoading() {
  const spacing = useGridSpacing();

  return (
    <HighlightSection>
      <Flexbox direction="column" alignItems="stretch" rowGap={spacing.rowGap}>
        <Flexbox direction="column">
          <SectionHeader title="My booked weeks" />
          <HighlightCard>
            <Flexbox justifyContent="space-around">
              <Skeleton.Input active style={{ width: '120px' }} />
              <Skeleton.Input active style={{ width: '120px' }} />
            </Flexbox>
            <Spacer height="20px" />
            <Skeleton active title={false} paragraph={{ rows: 6 }} />
          </HighlightCard>
        </Flexbox>
        <Flexbox justifyContent="center">
          <Skeleton.Input active style={{ width: '100px' }} />
        </Flexbox>
      </Flexbox>
    </HighlightSection>
  );
}

export default CalendarHighlightSection;
