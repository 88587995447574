import type { expense_categories_enum } from '../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';
import type { BudgetBreakdown } from './utils/getCategoriesBreakdown';

import { Pie } from '@ant-design/charts';
import React from 'react';
import { useFela } from 'react-fela';

import formatCurrency from '../../utils/formatCurrency';
import getCategoryColor, { CATEGORY_COLORS } from './utils/getCategoryColor';
import getCategoryTitle from './utils/getCategoryTitle';

type WalletBudgetBreakdownChartProps = {
  breakdown: BudgetBreakdown;
};

const styles: Record<string, AppFelaStyle> = {
  chart: ({ theme }) => ({
    padding: theme.xs ? '0 15px' : '0',
  }),
};

export default function WalletBudgetBreakdownChart({
  breakdown,
}: WalletBudgetBreakdownChartProps): JSX.Element {
  const { css, theme } = useFela<AppFelaTheme>();
  const usedCategories = new Set(breakdown.map((b) => b.category));
  const usedColors = (
    Object.keys(CATEGORY_COLORS) as Array<expense_categories_enum>
  )
    .filter((category) => usedCategories.has(category))
    .map((category) => getCategoryColor(category));

  const total = breakdown.reduce(
    (acc, category) => acc + (category.amount || 0),
    0,
  );

  return (
    <Pie
      className={css(styles.chart)}
      data={[...breakdown]}
      angleField="amount"
      colorField="category"
      radius={1}
      innerRadius={0.6}
      label={false}
      color={usedColors}
      statistic={{
        title: {
          content: formatCurrency(total),
          style: {
            color: theme.primaryColor,
            fontSize: theme.fontSizeTitle2,
          },
        },
        content: false,
      }}
      legend={false}
      tooltip={{
        formatter: (datum) => ({
          name: getCategoryTitle(datum.category),
          value: formatCurrency(datum.amount),
        }),
      }}
    />
  );
}
