import type { PropertyHotDataCardFragment$key } from '../../__generated__/relay/PropertyHotDataCardFragment.graphql';
import type { FelaStyle } from 'react-fela';

import { Divider } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import Flexbox from '../core/Flexbox';
import PropertyHotDataAccessCodeItem from './PropertyHotDataAccessCodeItem';
import PropertyHotDataAddressItem from './PropertyHotDataAddressItem';
import PropertyHotDataConciergeItem from './PropertyHotDataConciergeItem';
import PropertyHotDataWifiItem from './PropertyHotDataWifiItem';
import PropertyUpcomingStayCard from './PropertyUpcomingStayCard';

type FelaProps = {
  imageUrl: string;
  md: boolean;
};

const styles: Record<string, FelaStyle<{}, FelaProps>> = {
  items: ({ md }) => ({
    maxWidth: md ? '450px' : '100%',
  }),
  divider: {
    margin: '15px 0px',
  },
};

const DEFAULT_IMAGE = '/images/property_banner.png';

const fragment = graphql`
  fragment PropertyHotDataCardFragment on property_fractions {
    property {
      imageUrl: image_url
    }
    ...PropertyUpcomingStayCardFragment
    ...PropertyHotDataAccessCodeItemFragment
    ...PropertyHotDataAddressItemFragment
    ...PropertyHotDataConciergeItemFragment
    ...PropertyHotDataWifiItemFragment
  }
`;

type Props = {
  fragmentRef: PropertyHotDataCardFragment$key;
};

function PropertyHotDataCard({ fragmentRef }: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const { md } = useBreakpoint();
  const { css } = useFela<{}, FelaProps>({
    imageUrl: fraction.property?.imageUrl?.toString() ?? DEFAULT_IMAGE,
    md: md === true,
  });

  return (
    <Flexbox direction="column" className={css(styles.items)}>
      {[
        PropertyUpcomingStayCard({ fragmentRef: fraction }),
        PropertyHotDataConciergeItem({ fragmentRef: fraction }),
        PropertyHotDataAddressItem({ fragmentRef: fraction }),
        PropertyHotDataAccessCodeItem({ fragmentRef: fraction }),
        PropertyHotDataWifiItem({ fragmentRef: fraction }),
      ]
        .filter(Boolean)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`i${index}`}>
            {index > 0 && <Divider className={css(styles.divider)} />}
            {item}
          </React.Fragment>
        ))}
    </Flexbox>
  );
}

export default PropertyHotDataCard;
