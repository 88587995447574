import { expense_categories_enum } from '../../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';

const CATEGORY_MAPPING: Record<expense_categories_enum, number> = {
  MAINTENANCE: 0,
  PROPERTY_MANAGEMENT: 1,
  REPAIRS_RESERVE: 2,
  HOA: 3,
  UTILITIES: 4,
  HOUSEKEEPING: 5,
  INSURANCE: 6,
  PROPERTY_TAXES: 7,
  '%future added value': 999,
};

const getCategoryOrder = (categoryEnum: expense_categories_enum): number =>
  CATEGORY_MAPPING[categoryEnum] ?? 999;

export default getCategoryOrder;
