import type { PropertyHotDataAccessCodeItemFragment$key } from '../../__generated__/relay/PropertyHotDataAccessCodeItemFragment.graphql';

import { LockOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import isEmpty from '../../utils/isEmpty';
import ActionIconCopy from '../ActionIconCopy';
import PropertyHotDataItem from './PropertyHotDataItem';

const { Text } = Typography;

const fragment = graphql`
  fragment PropertyHotDataAccessCodeItemFragment on property_fractions {
    property {
      accessCode: access_code
    }
  }
`;

type Props = {
  fragmentRef: PropertyHotDataAccessCodeItemFragment$key;
};

function PropertyHotDataAccessCodeItem({
  fragmentRef,
}: Props): JSX.Element | null {
  const { property } = useFragment(fragment, fragmentRef);
  const accessCode = property.accessCode as unknown as string;

  if (isEmpty(accessCode)) {
    return null;
  }

  return (
    <PropertyHotDataItem
      title="Unlock home"
      Icon={LockOutlined}
      content={
        <Text>
          <Text type="secondary">Home Access Code:</Text> {accessCode}
        </Text>
      }
      action={
        <ActionIconCopy
          text={accessCode.toString()}
          tooltips={['Copy code', 'Copied']}
        />
      }
    />
  );
}

export default PropertyHotDataAccessCodeItem;
