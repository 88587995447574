/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyHotDataCardFragment = {
    readonly property: {
        readonly imageUrl: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"PropertyUpcomingStayCardFragment" | "PropertyHotDataAccessCodeItemFragment" | "PropertyHotDataAddressItemFragment" | "PropertyHotDataConciergeItemFragment" | "PropertyHotDataWifiItemFragment">;
    readonly " $refType": "PropertyHotDataCardFragment";
};
export type PropertyHotDataCardFragment$data = PropertyHotDataCardFragment;
export type PropertyHotDataCardFragment$key = {
    readonly " $data"?: PropertyHotDataCardFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyHotDataCardFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyHotDataCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "imageUrl",
          "args": null,
          "kind": "ScalarField",
          "name": "image_url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyUpcomingStayCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyHotDataAccessCodeItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyHotDataAddressItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyHotDataConciergeItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyHotDataWifiItemFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'c6f609ea36ccf0d34c889d5222cd2479';
export default node;
