import type { useNextPaymentDateFragment$key } from '../../../__generated__/relay/useNextPaymentDateFragment.graphql';

import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useFragment } from 'react-relay/hooks';

const fragment = graphql`
  fragment useNextPaymentDateFragment on property_fractions {
    boughtOn: bought_on
    property {
      ownerPaymentDayOfMonth: owner_payments_day_of_month
    }
  }
`;

function useNextPaymentDate(fragmentRef: useNextPaymentDateFragment$key) {
  const { boughtOn, property } = useFragment(fragment, fragmentRef);
  const { ownerPaymentDayOfMonth } = property;

  return useMemo(() => {
    let paydate = dayjs().date(ownerPaymentDayOfMonth);

    const today = dayjs();
    const boughtOnDate = dayjs(boughtOn);
    while (paydate < today || paydate < boughtOnDate) {
      paydate = paydate.add(1, 'month');
    }

    return paydate;
  }, [boughtOn, ownerPaymentDayOfMonth]);
}

export default useNextPaymentDate;
