import type { AppFelaStyle, AppFelaTheme } from '../styles/AppThemeProvider';

import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  hrefIcon: ({ theme }) => ({
    color: theme.primaryColor,
    fontSize: '20px',
    padding: '2px',
    '& :hover': {
      color: theme.ligthColor,
    },
  }),
};

type Props = Readonly<{
  text: string;
  tooltips?: [React.ReactNode, React.ReactNode];
}>;

function InfoCard({ text, tooltips }: Props): JSX.Element {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Text
      className={css(styles.hrefIcon)}
      copyable={{
        text,
        tooltips,
        icon: <CopyOutlined className={css(styles.hrefIcon)} />,
      }}
    />
  );
}

export default InfoCard;
