/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileAvatarFragment = {
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly " $refType": "ProfileAvatarFragment";
};
export type ProfileAvatarFragment$data = ProfileAvatarFragment;
export type ProfileAvatarFragment$key = {
    readonly " $data"?: ProfileAvatarFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileAvatarFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "firstName",
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": "lastName",
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = '8599a9c330ebec75043dbe4e2a0552ff';
export default node;
