import type { FelaStyle } from 'react-fela';

import React from 'react';
import { useFela } from 'react-fela';

const DEFAULT = 'initial';

type SpacerProps = {
  width?: string;
  height?: string;
  grow?: number;
  shrink?: number;
};

const styles: Record<string, FelaStyle<{}, SpacerProps>> = {
  spacer: ({
    width = '1px',
    height = '1px',
    grow = DEFAULT,
    shrink = DEFAULT,
  }) => ({
    width,
    height,
    flexGrow: grow,
    flexShrink: shrink,
  }),
};

type Props = SpacerProps &
  Readonly<{
    className?: string;
  }>;

function Spacer({ className: classNameProp, ...flexProps }: Props) {
  const { css } = useFela<{}, SpacerProps>(flexProps);

  const classNameCombined = [css(styles.spacer), classNameProp]
    .filter(Boolean)
    .join(' ');

  return <div className={classNameCombined} />;
}

export default Spacer;
