import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PAGE_TITLE_MAP = new Map([
  ['/myhome', 'Home'],
  ['/calendar', 'Calendar'],
  ['/wallet', 'Wallet'],
  ['/auth/login', 'Login'],
  ['/auth/forgot', 'Password recovery'],
  ['/profile', 'Profile'],
  ['/profile/change_password', 'Change Password'],
]);

const usePageTitleUpdater = () => {
  const location = useLocation();
  const PAGE_PREFIX = 'My Kocomo';
  const PAGE_UNION = ' - ';
  const title = PAGE_TITLE_MAP.get(location.pathname);
  useEffect(() => {
    document.title = [PAGE_PREFIX, title].filter(Boolean).join(PAGE_UNION);
  }, [location, title]);
};

export default usePageTitleUpdater;
