/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type booking_types_enum = "EXCHANGE" | "GIFT" | "RENTAL" | "STAY" | "%future added value";
export type CalendarYearBookingsFragment = {
    readonly bookings: ReadonlyArray<{
        readonly id: string;
        readonly dateStart: string;
        readonly dateEnd: string;
        readonly weeksCount: number;
        readonly bookingType: booking_types_enum;
    }>;
    readonly " $refType": "CalendarYearBookingsFragment";
};
export type CalendarYearBookingsFragment$data = CalendarYearBookingsFragment;
export type CalendarYearBookingsFragment$key = {
    readonly " $data"?: CalendarYearBookingsFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CalendarYearBookingsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CalendarYearBookingsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "date_start": "asc"
          }
        }
      ],
      "concreteType": "bookings",
      "kind": "LinkedField",
      "name": "bookings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "dateStart",
          "args": null,
          "kind": "ScalarField",
          "name": "date_start",
          "storageKey": null
        },
        {
          "alias": "dateEnd",
          "args": null,
          "kind": "ScalarField",
          "name": "date_end",
          "storageKey": null
        },
        {
          "alias": "weeksCount",
          "args": null,
          "kind": "ScalarField",
          "name": "weeks_count",
          "storageKey": null
        },
        {
          "alias": "bookingType",
          "args": null,
          "kind": "ScalarField",
          "name": "booking_type",
          "storageKey": null
        }
      ],
      "storageKey": "bookings(order_by:{\"date_start\":\"asc\"})"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'bf82481048bf02347b057049687161e4';
export default node;
