import type { AuthError } from 'firebase/auth';

import { captureException } from '@sentry/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useCallback, useState, useMemo } from 'react';

import { auth } from '../utils/firebaseApp';

export type ForgotPasswordActionHook = [
  (email: string) => void,
  boolean,
  AuthError | undefined,
  boolean,
];

function useSendPasswordResetEmail(): ForgotPasswordActionHook {
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const signIn = useCallback(async (email: string) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setLoading(false);
      setSent(true);
    } catch (err) {
      setError(err as AuthError);
      setLoading(false);
      captureException(err);
    }
  }, []);

  return useMemo<ForgotPasswordActionHook>(
    () => [signIn, loading, error, sent],
    [signIn, loading, error, sent],
  );
}

export default useSendPasswordResetEmail;
