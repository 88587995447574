import type {
  WalletBudgetBreakdownFragment,
  expense_categories_enum,
} from '../../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';

import orEmptyArray from '../../../utils/orEmptyArray';
import getCategoryOrder from './getCategoryOrder';

type Budget = NonNullable<
  WalletBudgetBreakdownFragment['property']['budgets'][0]
>;

export enum BudgetPerdioDisplay {
  MONTHLY = 'Per month',
  YEARLY = 'Per year',
}

export type BudgetBreakdown = ReadonlyArray<{
  category: expense_categories_enum;
  amount: number;
  percentage: number;
}>;

const getCategoriesBreakdown = (
  budget: Budget | null,
  total: number | null,
  display: BudgetPerdioDisplay,
  ownerShares: number,
  propertyShares: number,
): BudgetBreakdown => {
  const budgetDetails = orEmptyArray(budget?.budgetDetails);
  if (budgetDetails.length === 0 || total == null) {
    return [];
  }

  const budgetPerCategory = budgetDetails.reduce<
    Map<expense_categories_enum, number>
  >((accumulative, { category, amount }) => {
    accumulative.set(category, amount + (accumulative.get(category) ?? 0));
    return accumulative;
  }, new Map());

  const displayFactor = display === BudgetPerdioDisplay.MONTHLY ? 12 : 1;

  const breakdownList = Array.from(budgetPerCategory, ([category, amount]) => ({
    category,
    amount: (amount * ownerShares) / (propertyShares * displayFactor),
    percentage: (100 * amount) / total,
  }));

  return breakdownList.sort(
    (exp1, exp2) =>
      getCategoryOrder(exp1.category) - getCategoryOrder(exp2.category),
  );
};

export default getCategoriesBreakdown;
