import type { ProfileInfoCardQuery } from '../../__generated__/relay/ProfileInfoCardQuery.graphql';

import { Skeleton, Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { Suspense, useState, useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';

import useAuthState from '../../hooks/useAuthState';
import useGridSpacing from '../../hooks/useGridSpacing';
import Divider from '../core/Divider';
import Flexbox from '../core/Flexbox';
import ErrorBoundary from '../ErrorBoundary';
import HighlightCard from '../HighlightCard';
import Labeled from '../Labeled';
import ProfileAvatar from './ProfileAvatar';
import ProfileFirstName from './ProfileFirstName';
import ProfileLastName from './ProfileLastName';
import ProfilePhone from './ProfilePhone';

const { Text } = Typography;

const query = graphql`
  query ProfileInfoCardQuery {
    me: me_connection {
      edges {
        node {
          id
          email
          roles: user_roles {
            role: user_role
          }
          ...ProfileAvatarFragment
          ...ProfileFirstNameFragment
          ...ProfileLastNameFragment
          ...ProfilePhoneFragment
        }
      }
    }
  }
`;

function ProfileInfoCard(): JSX.Element {
  const data = useLazyLoadQuery<ProfileInfoCardQuery>(query, {});
  const spacing = useGridSpacing();

  const user = data.me.edges[0].node;
  const isAdmin = user.roles.some((r) => r.role === 'ADMINISTRATOR');
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const showtoken = isDev || isAdmin;

  return (
    <HighlightCard>
      <Flexbox direction="column" rowGap="20px">
        <Flexbox
          direction="row"
          alignItems="stretch"
          columnGap={spacing.columnGap}
        >
          <ProfileAvatar fragmentRef={user} />
          <Flexbox direction="column" rowGap="20px" grow={1}>
            <Labeled title="First name">
              <ProfileFirstName fragmentRef={user} />
            </Labeled>
            <Labeled title="Last name">
              <ProfileLastName fragmentRef={user} />
            </Labeled>
          </Flexbox>
        </Flexbox>
        <Divider />
        <Labeled title="Email">{user.email}</Labeled>
        <Labeled title="Phone">
          <ProfilePhone fragmentRef={user} />
        </Labeled>
        {showtoken && (
          <Labeled title="Token">
            <UserToken />
          </Labeled>
        )}
      </Flexbox>
    </HighlightCard>
  );
}

function UserToken(): JSX.Element {
  const [currentUser, loading] = useAuthState();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (currentUser != null) {
        const newtoken = await currentUser.getIdToken();
        setToken(newtoken);
      }
    }
    fetchData();
  }, [currentUser, setToken]);

  if (loading) {
    return <Skeleton.Input active size="small" />;
  }

  if (token == null) {
    return <Text>Token not found</Text>;
  }

  return (
    <Text ellipsis copyable>
      {token}
    </Text>
  );
}

function ProfileInfoCardWrapper(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <ProfileInfoCard />
      </Suspense>
    </ErrorBoundary>
  );
}

function Loading(): JSX.Element {
  const spacing = useGridSpacing();
  return (
    <HighlightCard>
      <Flexbox direction="column" rowGap="20px">
        <Flexbox
          direction="row"
          alignItems="stretch"
          columnGap={spacing.columnGap}
        >
          <Skeleton.Avatar style={{ width: 105, height: 105 }} active />
          <Flexbox direction="column" rowGap="20px" grow={1}>
            <Labeled title="First name">
              <Skeleton.Input active size="small" />
            </Labeled>
            <Labeled title="Last name">
              <Skeleton.Input active size="small" />
            </Labeled>
          </Flexbox>
        </Flexbox>
        <Divider />
        <Labeled title="Email">
          <Skeleton.Input active size="small" />
        </Labeled>
        <Labeled title="Phone">
          <Skeleton.Input active size="small" />
        </Labeled>
      </Flexbox>
    </HighlightCard>
  );
}

export default ProfileInfoCardWrapper;
