import type { FelaStyle } from 'react-fela';

import { LogoutOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import useSignOut from '../hooks/useSignOut';
import Flexbox from './core/Flexbox';

const { Link, Text } = Typography;

const styles: Record<string, FelaStyle<{}>> = {
  completeRow: {
    height: '100vh',
    backgroundColor: '#e5e5e5',
  },
  image: {
    width: '100%',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'underline !important',
  },
  button: {
    marginTop: '20px',
    background: 'transparent',
  },
};

function AccountErrorCard(): JSX.Element {
  const { css } = useFela();
  const signOutHandler = useSignOut();

  return (
    <Row justify="center" align="middle" className={css(styles.completeRow)}>
      <Col>
        <Flexbox direction="column" alignItems="center" rowGap="5px">
          <img
            src="/images/empty_suitcase.svg"
            className={css(styles.image)}
            alt=""
          />
          <Text>Oops! There is an error with your account.</Text>
          <Text>
            Try again later or{' '}
            <Link className={css(styles.link)} href="mailto:info@kocomo.com">
              contact us
            </Link>
          </Text>
          <Button className={css(styles.button)} onClick={signOutHandler}>
            <LogoutOutlined />
            <Text className={css(styles.itemText)}>Log out</Text>
          </Button>
        </Flexbox>
      </Col>
    </Row>
  );
}

export default AccountErrorCard;
