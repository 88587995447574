import { captureException } from '@sentry/react';
import { User } from 'firebase/auth';
import jwt_decode from 'jwt-decode';

import { auth } from './firebaseApp';

type Token = {
  user?: {
    id?: string;
  };
};

function observeUserSessionChanges(cb: (userId: string | null) => void) {
  auth.onAuthStateChanged(async (firebaseUser: User | null) => {
    if (firebaseUser == null) {
      cb(null);
      return;
    }
    const token = await firebaseUser.getIdToken();
    const tokenInfo: Token | null = jwt_decode(token);
    const id = tokenInfo?.user?.id || firebaseUser.uid;
    cb(id);
  }, captureException);
}

export default observeUserSessionChanges;
