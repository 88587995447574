/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyHotDataAddressItemFragment = {
    readonly property: {
        readonly address: {
            readonly street1: string | null;
            readonly street2: string | null;
            readonly dependentLocality: string | null;
            readonly locality: string | null;
            readonly state: string | null;
            readonly country: string | null;
            readonly postalCode: string | null;
            readonly lngLat: unknown | null;
        } | null;
    };
    readonly " $refType": "PropertyHotDataAddressItemFragment";
};
export type PropertyHotDataAddressItemFragment$data = PropertyHotDataAddressItemFragment;
export type PropertyHotDataAddressItemFragment$key = {
    readonly " $data"?: PropertyHotDataAddressItemFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyHotDataAddressItemFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyHotDataAddressItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "addresses",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": "street1",
              "args": null,
              "kind": "ScalarField",
              "name": "street_1",
              "storageKey": null
            },
            {
              "alias": "street2",
              "args": null,
              "kind": "ScalarField",
              "name": "street_2",
              "storageKey": null
            },
            {
              "alias": "dependentLocality",
              "args": null,
              "kind": "ScalarField",
              "name": "dependent_locality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": "postalCode",
              "args": null,
              "kind": "ScalarField",
              "name": "postal_code",
              "storageKey": null
            },
            {
              "alias": "lngLat",
              "args": null,
              "kind": "ScalarField",
              "name": "lng_lat",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '1dcc2680276d7e896f384f662caed5d8';
export default node;
