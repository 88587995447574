import type { PropertyGuidelinesCardFragment$key } from '../../__generated__/relay/PropertyGuidelinesCardFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

const styles: Record<string, AppFelaStyle> = {
  collapsableContainer: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  collapsableItem: ({ theme }) => ({
    border: 'none',
    '& .ant-collapse-content': {
      borderTop: 'none',
    },
    '&:last-of-type': {
      borderRadius: '0 !important',
    },
    '& .ant-collapse-header': {
      fontSize: theme.xs ? theme.fontSizeBase : theme.fontSizeTitle4,
    },
  }),
};

const fragment = graphql`
  fragment PropertyGuidelinesCardFragment on property_manual_categories {
    id
    manuals(order_by: { order: asc }) {
      id
      manual {
        id
        title
        description: description_rich
      }
    }
  }
`;

const parseRichText = (description: string) => {
  try {
    return JSON.parse(description);
  } catch (error) {
    return null;
  }
};

type Props = {
  fragmentRef: PropertyGuidelinesCardFragment$key;
};

function PropertyGuidelinesCard({ fragmentRef }: Props): JSX.Element | null {
  const { manuals } = useFragment(fragment, fragmentRef);
  const { css } = useFela<AppFelaTheme>();

  return (
    <Collapse
      className={css(styles.collapsableContainer)}
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined /> : <PlusOutlined />
      }
      expandIconPosition="right"
    >
      {manuals
        .map((m) => m.manual)
        .map(({ id, title, description }) => (
          <Collapse.Panel
            key={id}
            header={title}
            className={css(styles.collapsableItem)}
          >
            <Editor
              initialContentState={parseRichText(description)}
              readOnly
              toolbarHidden
            />
          </Collapse.Panel>
        ))}
    </Collapse>
  );
}

export default PropertyGuidelinesCard;
