import type { PropertyHotDataWifiItemFragment$key } from '../../__generated__/relay/PropertyHotDataWifiItemFragment.graphql';

import { WifiOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import isEmpty from '../../utils/isEmpty';
import ActionIconCopy from '../ActionIconCopy';
import PropertyHotDataItem from './PropertyHotDataItem';

const { Text } = Typography;

const fragment = graphql`
  fragment PropertyHotDataWifiItemFragment on property_fractions {
    property {
      wifiName: wifi_name
      wifiPassword: wifi_password
    }
  }
`;

type Props = {
  fragmentRef: PropertyHotDataWifiItemFragment$key;
};

function PropertyHotDataWifiItem({ fragmentRef }: Props): JSX.Element | null {
  const { property } = useFragment(fragment, fragmentRef);
  const wifiName = property.wifiName as unknown as string;
  const wifiPassword = property.wifiPassword as unknown as string;

  if (isEmpty(wifiName) && isEmpty(wifiPassword)) {
    return null;
  }

  return (
    <PropertyHotDataItem
      title="Connect to WiFi"
      Icon={WifiOutlined}
      content={
        <div>
          <Text>
            <Text type="secondary">Network:</Text> {wifiName}
          </Text>
          <br />
          <Text>
            <Text type="secondary">Password:</Text> {wifiPassword}
          </Text>
        </div>
      }
      action={
        <ActionIconCopy
          text={wifiPassword.toString()}
          tooltips={['Copy password', 'Copied']}
        />
      }
    />
  );
}

export default PropertyHotDataWifiItem;
