/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type booking_types_enum = "EXCHANGE" | "GIFT" | "RENTAL" | "STAY" | "%future added value";
export type PropertyUpcomingStayCardFragment = {
    readonly property: {
        readonly name: string;
        readonly conciergeWhatsapp: string | null;
    };
    readonly bookings: ReadonlyArray<{
        readonly id: string;
        readonly dateStart: string;
        readonly dateEnd: string;
        readonly bookingType: booking_types_enum;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"useConciergeHrefFragment">;
    readonly " $refType": "PropertyUpcomingStayCardFragment";
};
export type PropertyUpcomingStayCardFragment$data = PropertyUpcomingStayCardFragment;
export type PropertyUpcomingStayCardFragment$key = {
    readonly " $data"?: PropertyUpcomingStayCardFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyUpcomingStayCardFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "today"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyUpcomingStayCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": "conciergeWhatsapp",
          "args": null,
          "kind": "ScalarField",
          "name": "concierge_whatsapp",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "date_start": "asc"
          }
        },
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "_or",
              "value": [
                {
                  "booking_type": {
                    "_eq": "STAY"
                  }
                },
                {
                  "booking_type": {
                    "_eq": "RENTAL"
                  }
                }
              ]
            },
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_gte",
                  "variableName": "today"
                }
              ],
              "kind": "ObjectValue",
              "name": "date_start"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "bookings",
      "kind": "LinkedField",
      "name": "bookings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "dateStart",
          "args": null,
          "kind": "ScalarField",
          "name": "date_start",
          "storageKey": null
        },
        {
          "alias": "dateEnd",
          "args": null,
          "kind": "ScalarField",
          "name": "date_end",
          "storageKey": null
        },
        {
          "alias": "bookingType",
          "args": null,
          "kind": "ScalarField",
          "name": "booking_type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConciergeHrefFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '54d5482ca24570d97483d446c476e41e';
export default node;
