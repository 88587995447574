import type { FelaStyle } from 'react-fela';

import { Typography } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from './core/Flexbox';

const styles: Record<string, FelaStyle<{}>> = {
  title: {
    fontSize: '12px',
  },
};

const { Text } = Typography;

type Props = Readonly<{
  title: string;
  children: React.ReactNode;
}>;

function Labeled({ title, children }: Props) {
  const { css } = useFela();

  return (
    <Flexbox direction="column">
      <Text type="secondary" className={css(styles.title)}>
        {title}
      </Text>
      {children}
    </Flexbox>
  );
}

export default Labeled;
