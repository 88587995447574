import type { FelaStyle } from 'react-fela';

import { Row } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const styles: Record<string, FelaStyle<{}>> = {
  image: {
    width: '230px',
    marginTop: '60px',
  },
};

function ProfileFooterImage(): JSX.Element {
  const { css } = useFela<{}>();

  return (
    <Row justify="center">
      <img
        src="/images/interior_and_cash.svg"
        className={css(styles.image)}
        alt=""
      />
    </Row>
  );
}

export default ProfileFooterImage;
