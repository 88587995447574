import React from 'react';
import { useFela } from 'react-fela';

import { AppFelaStyle, AppFelaTheme } from '../styles/AppThemeProvider';
import Flexbox from './core/Flexbox';

const styles: Record<string, AppFelaStyle> = {
  body: ({ theme }) => ({
    width: theme.lg ? '1220px' : '944px',
    maxWidth: '100%',
    overflow: 'hidden',
  }),
};

type Props = {
  children: React.ReactNode;
};

function PageBody({ children }: Props): JSX.Element {
  const { css } = useFela<AppFelaTheme>();

  return (
    <Flexbox justifyContent="center">
      <div className={css(styles.body)}>{children}</div>
    </Flexbox>
  );
}

export default PageBody;
