import type { FelaStyle } from 'react-fela';

import React from 'react';
import { useFela } from 'react-fela';

const DEFAULT = 'initial';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
type FlexJustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'start'
  | 'end'
  | 'left'
  | 'right'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
type FlexAlignItems = 'stretch' | 'start' | 'end' | 'center' | 'baseline';
type FlexAlignContent =
  | 'normal'
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

type FlexProps = {
  direction?: FlexDirection;
  wrap?: FlexWrap;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  alignContent?: FlexAlignContent;
  order?: number;
  grow?: number;
  shrink?: number;
  basis?: string;
  alignSelf?: FlexAlignItems;
  rowGap?: string;
  columnGap?: string;
};

const styles: Record<string, FelaStyle<{}, FlexProps>> = {
  flexbox: ({
    direction = DEFAULT,
    wrap = DEFAULT,
    justifyContent = DEFAULT,
    alignItems = DEFAULT,
    alignContent = DEFAULT,
    order = DEFAULT,
    grow = DEFAULT,
    shrink = DEFAULT,
    basis = DEFAULT,
    alignSelf = DEFAULT,
    rowGap = DEFAULT,
    columnGap = DEFAULT,
  }) => ({
    display: 'flex',
    maxWidth: '100%',
    flexDirection: direction,
    flexWrap: wrap,
    justifyContent,
    alignItems,
    alignContent,
    order,
    flexGrow: grow,
    flexShrink: shrink,
    flexBasis: basis,
    alignSelf,
    rowGap,
    columnGap,
  }),
};

type Props = FlexProps &
  Readonly<{
    children?: React.ReactNode;
    className?: string;
  }>;

function Flexbox({ children, className: classNameProp, ...flexProps }: Props) {
  const { css } = useFela<{}, FlexProps>(flexProps);

  const classNameCombined = [css(styles.flexbox), classNameProp]
    .filter(Boolean)
    .join(' ');

  return <div className={classNameCombined}>{children}</div>;
}

export default Flexbox;
