import React from 'react';

import Button from '../core/Button';
import Flexbox from '../core/Flexbox';

type Props = {
  loading: boolean;
};

function ProfileUpdatePasswordActions({ loading }: Props): JSX.Element {
  return (
    <Flexbox columnGap="20px" rowGap="20px" wrap="wrap-reverse">
      <Flexbox grow={1} basis="10px">
        <Button loading={loading} disabled={loading} href="/profile" block>
          Back
        </Button>
      </Flexbox>
      <Flexbox grow={1} basis="10px">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          block
        >
          Update password
        </Button>
      </Flexbox>
    </Flexbox>
  );
}

export default ProfileUpdatePasswordActions;
