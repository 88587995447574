import { captureException } from '@sentry/react';
import {
  AuthError,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import nullthrows from 'nullthrows';
import { useCallback, useState, useMemo } from 'react';

import useAuthState from './useAuthState';

export type UpdatePasswordActionHook = [
  (oldPassword: string, newPassword: string) => Promise<boolean>,
  boolean,
  AuthError | undefined,
];

function useUpdatePassword(): UpdatePasswordActionHook {
  const [user] = useAuthState();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);

  const update = useCallback(
    async (oldPassword: string, newPassword: string): Promise<boolean> => {
      setLoading(true);
      setError(undefined);
      try {
        const credential = EmailAuthProvider.credential(
          nullthrows(user?.email),
          oldPassword,
        );
        await reauthenticateWithCredential(user!, credential);
        await updatePassword(user!, newPassword);
        setLoading(false);
        return true;
      } catch (err) {
        setError(err as AuthError);
        setLoading(false);
        captureException(err);
        return false;
      }
    },
    [user],
  );

  return useMemo<UpdatePasswordActionHook>(
    () => [update, loading, error],
    [update, loading, error],
  );
}

export default useUpdatePassword;
