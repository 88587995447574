/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type WalletHighlightSectionFragment = {
    readonly " $fragmentRefs": FragmentRefs<"WalletUpcomingPaymentCardFragment" | "useConciergeHrefFragment">;
    readonly " $refType": "WalletHighlightSectionFragment";
};
export type WalletHighlightSectionFragment$data = WalletHighlightSectionFragment;
export type WalletHighlightSectionFragment$key = {
    readonly " $data"?: WalletHighlightSectionFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WalletHighlightSectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletHighlightSectionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WalletUpcomingPaymentCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConciergeHrefFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '43556a055325611071eece6aff0c9477';
export default node;
