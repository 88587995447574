import type { AuthError, UserCredential } from 'firebase/auth';

import { captureException } from '@sentry/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useCallback, useState, useMemo } from 'react';

import { useResetRelayEnvironment } from '../contexts/ResettableRelayEnvironmentContext';
import { auth } from '../utils/firebaseApp';

export type EmailAndPasswordActionHook = [
  (email: string, password: string) => void,
  boolean,
  AuthError | undefined,
  UserCredential | undefined,
];

function useSignInWithEmailAndPassword(): EmailAndPasswordActionHook {
  const resetRelayEnvironment = useResetRelayEnvironment();
  const [error, setError] = useState<AuthError>();
  const [loggedInUser, setLoggedInUser] = useState<UserCredential>();
  const [loading, setLoading] = useState<boolean>(false);

  const signIn = useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      try {
        const user = await signInWithEmailAndPassword(auth, email, password);
        setLoggedInUser(user);
        setLoading(false);
        resetRelayEnvironment();
      } catch (err) {
        setError(err as AuthError);
        setLoading(false);
        captureException(err);
      }
    },
    [resetRelayEnvironment],
  );

  return useMemo<EmailAndPasswordActionHook>(
    () => [signIn, loading, error, loggedInUser],
    [signIn, loading, error, loggedInUser],
  );
}

export default useSignInWithEmailAndPassword;
