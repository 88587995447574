import { init, configureScope, setUser } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import observeUserSessionChanges from './observeUserSessionChanges';

function detectSession() {
  observeUserSessionChanges((id) => {
    if (id == null) {
      configureScope((scope) => scope.setUser(null));
      return;
    }
    setUser({ id });
  });
}

function initSentry() {
  init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    environment:
      process.env.NODE_ENV === 'production' ? 'Production' : 'Development',
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0,
  });
  detectSession();
}

initSentry();
