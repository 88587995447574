import type { AuthError } from 'firebase/auth';
import type { FelaStyle } from 'react-fela';

import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import { AuthErrorCodes } from 'firebase/auth';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';

import Labeled from '../../components/Labeled';
import useSendPasswordResetEmail from '../../hooks/useSendPasswordResetEmail';

type FormItemRule = React.ComponentProps<typeof Form.Item>['rules'];
type ForgotPasswordFormValues = {
  email: string;
};

const { Paragraph, Title } = Typography;

const styles: Record<string, FelaStyle<{}>> = {
  title: {
    textAlign: 'center',
    marginBottom: '40px !important',
  },
  button: {
    marginBottom: '10px',
  },
  centered: {
    textAlign: 'center',
  },
};

const RULES: Record<string, FormItemRule> = {
  email: [
    { type: 'email', message: 'The input is not valid e-mail' },
    { required: true, message: 'Please input your e-mail' },
  ],
};

const getErrorMessage = (error: AuthError): string => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_EMAIL:
      return 'Email address is not valid';
    case AuthErrorCodes.USER_DELETED:
      return 'User not found';
    case AuthErrorCodes.INVALID_CONTINUE_URI:
    case AuthErrorCodes.MISSING_ANDROID_PACKAGE_NAME:
    case AuthErrorCodes.MISSING_CONTINUE_URI:
    case AuthErrorCodes.MISSING_IOS_BUNDLE_ID:
    case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
    default:
      return 'There was an error, please try again later';
  }
};

function ForgotPasswordPage(): JSX.Element {
  const { css } = useFela();

  const [sendPasswordResetEmail, loading, error, sent] =
    useSendPasswordResetEmail();

  const forgotHandler = useCallback(
    (values: ForgotPasswordFormValues) => {
      sendPasswordResetEmail(values.email);
    },
    [sendPasswordResetEmail],
  );

  return (
    <>
      <Title level={2} className={css(styles.title)}>
        Reset your password
      </Title>
      <Paragraph>
        Enter your email and we&apos;ll send you a link to reset your password.
      </Paragraph>
      <Form name="forgot" onFinish={forgotHandler} validateTrigger="onSubmit">
        <Labeled title="Email">
          <Form.Item name="email" rules={RULES.email}>
            <Input />
          </Form.Item>
        </Labeled>
        <Form.Item>
          {error && (
            <Paragraph type="danger">{getErrorMessage(error)}</Paragraph>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            className={css(styles.button)}
            block
          >
            Send email
          </Button>
          {sent && (
            <Paragraph type="secondary" className={css(styles.centered)}>
              Did not get an email? Check your spam inbox.
            </Paragraph>
          )}
          <Paragraph className={css(styles.centered)}>
            <Link to="/auth/login">
              <LeftOutlined style={{ marginRight: '10px' }} />
              Back to Login
            </Link>
          </Paragraph>
        </Form.Item>
      </Form>
    </>
  );
}

export default ForgotPasswordPage;
