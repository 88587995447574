import type { ProfilePhoneFragment$key } from '../../__generated__/relay/ProfilePhoneFragment.graphql';
import type { ProfilePhoneMutation } from '../../__generated__/relay/ProfilePhoneMutation.graphql';

import { Typography } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React, { useCallback } from 'react';
import { useFragment, useMutation } from 'react-relay/hooks';

import useErrorHandler from '../../hooks/useErrorHandler';

const { Text } = Typography;

const fragment = graphql`
  fragment ProfilePhoneFragment on users {
    id
    userId: user_id
    phone
  }
`;

const mutation = graphql`
  mutation ProfilePhoneMutation($user_id: uuid!, $data: users_set_input) {
    update_users_by_pk(pk_columns: { user_id: $user_id }, _set: $data) {
      id
      phone
    }
  }
`;

type Props = {
  fragmentRef: ProfilePhoneFragment$key;
};

function ProfilePhone({ fragmentRef }: Props): JSX.Element {
  const { id, userId, phone } = useFragment(fragment, fragmentRef);
  const onError = useErrorHandler('Error updating phone');

  const [commit] = useMutation<ProfilePhoneMutation>(mutation);
  const onChange = useCallback(
    (newPhone) => {
      commit({
        variables: {
          user_id: userId,
          data: { phone: newPhone },
        },
        optimisticResponse: {
          update_users_by_pk: {
            id,
            phone: newPhone,
          },
        },
        onError,
      });
    },
    [commit, onError, userId, id],
  );

  return <Text editable={{ onChange }}>{phone}</Text>;
}

export default ProfilePhone;
