/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileLastNameFragment = {
    readonly id: string;
    readonly userId: string;
    readonly lastName: string | null;
    readonly " $refType": "ProfileLastNameFragment";
};
export type ProfileLastNameFragment$data = ProfileLastNameFragment;
export type ProfileLastNameFragment$key = {
    readonly " $data"?: ProfileLastNameFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileLastNameFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileLastNameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "userId",
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": "lastName",
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = '4bbce2828b5b0cb255745ee5ca9a56e8';
export default node;
