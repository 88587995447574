/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoggedInHeaderFragment = {
    readonly " $fragmentRefs": FragmentRefs<"PageTabsFragment" | "ProfileDropdownFragment">;
    readonly " $refType": "LoggedInHeaderFragment";
};
export type LoggedInHeaderFragment$data = LoggedInHeaderFragment;
export type LoggedInHeaderFragment$key = {
    readonly " $data"?: LoggedInHeaderFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LoggedInHeaderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoggedInHeaderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageTabsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileDropdownFragment"
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = '441e75e0e3357c87223f9ad98732e211';
export default node;
