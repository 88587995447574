/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileFirstNameFragment = {
    readonly id: string;
    readonly userId: string;
    readonly firstName: string | null;
    readonly " $refType": "ProfileFirstNameFragment";
};
export type ProfileFirstNameFragment$data = ProfileFirstNameFragment;
export type ProfileFirstNameFragment$key = {
    readonly " $data"?: ProfileFirstNameFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileFirstNameFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileFirstNameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "userId",
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": "firstName",
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
(node as any).hash = '93d6f60d2ff3de5995f38655d6a642d5';
export default node;
