import type { FetchFunction } from 'relay-runtime';

import nullthrows from 'nullthrows';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { auth } from './firebaseApp';

const fetchRelay: FetchFunction = async function fetchRelay(params, variables) {
  const { REACT_APP_GRAPHQL_ENDPOINT } = process.env;
  const url = nullthrows(
    REACT_APP_GRAPHQL_ENDPOINT,
    'This app requires a GraphQL to be configured.',
  );

  const token = nullthrows(
    await auth.currentUser?.getIdToken(),
    'This app requires a authentication token to be configured.',
  );

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  return response.json();
};

export default function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(
      new RecordSource(),
      // This property tells Relay to not immediately clear its cache when the user
      // navigates around the app. Relay will hold onto the specified number of
      // query results, allowing the user to return to recently visited pages
      // and reusing cached data if its available/fresh.
      { gcReleaseBufferSize: 10 },
    ),
  });
}
