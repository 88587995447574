import type { CalendarBookingsCardFragment$key } from '../../__generated__/relay/CalendarBookingsCardFragment.graphql';

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import HighlightCard from '../HighlightCard';
import CalendarBookingsTable from './CalendarBookingsTable';
import CalendarBookingTypeCheckboxGroup, {
  BookingType,
} from './CalendarBookingTypeCheckboxGroup';

const fragment = graphql`
  fragment CalendarBookingsCardFragment on property_fractions {
    ...CalendarBookingsTableFragment
  }
`;

type Props = {
  fragmentRef: CalendarBookingsCardFragment$key;
  selectedBookingTypes: BookingType[];
  setSelectedBookingTypes: (newSelection: BookingType[]) => void;
};

function CalendarBookingsCard({
  fragmentRef,
  selectedBookingTypes,
  setSelectedBookingTypes,
}: Props) {
  const fraction = useFragment(fragment, fragmentRef);

  return (
    <HighlightCard>
      <CalendarBookingTypeCheckboxGroup
        selectedValues={selectedBookingTypes}
        setSelectedValues={setSelectedBookingTypes}
      />
      <CalendarBookingsTable
        fragmentRef={fraction}
        selectedBookingTypes={selectedBookingTypes}
      />
    </HighlightCard>
  );
}

export default CalendarBookingsCard;
