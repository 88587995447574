import type { WalletBudgetBreakdownFragment$key } from '../../__generated__/relay/WalletBudgetBreakdownFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Col, Row, Skeleton, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import graphql from 'babel-plugin-relay/macro';
import React, { useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import useGridSpacing from '../../hooks/useGridSpacing';
import Flexbox from '../core/Flexbox';
import PageBody from '../PageBody';
import getCategoriesBreakdown, {
  BudgetPerdioDisplay,
} from './utils/getCategoriesBreakdown';
import WalletBudgetBreakdownChart from './WalletBudgetBreakdownChart';
import WalletBudgetBreakdownDisplaySelector from './WalletBudgetBreakdownDisplaySelector';
import WalletBudgetBreakdownTable from './WalletBudgetBreakdownTable';

const { Text } = Typography;

const styles: Record<string, AppFelaStyle> = {
  title: ({ theme }) => ({
    color: theme.primaryColor,
    fontSize: theme.fontSizeTitle1,
  }),
  subtitle: ({ theme }) => ({
    fontSize: theme.fontSizeTitle3,
  }),
  selectorContainer: {
    padding: '0 15px',
  },
};

const fragment = graphql`
  fragment WalletBudgetBreakdownFragment on property_fractions {
    fractionCount: fraction_count
    property {
      fractionCount: total_fraction_count
      budgets: annual_budgets(where: { year: { _eq: $budgetYear } }) {
        year
        totalAmount: total_amount
        budgetDetails: annual_budget_details {
          id
          title
          category
          amount
        }
      }
    }
  }
`;

type Props = {
  fragmentRef: WalletBudgetBreakdownFragment$key;
};

function WalletBudgetBreakdown({ fragmentRef }: Props): JSX.Element {
  const { css, theme } = useFela<AppFelaTheme>();
  const spacing = useGridSpacing();

  const { property, fractionCount } = useFragment(fragment, fragmentRef);
  const budget = property.budgets[0];

  const [display, setDisplay] = useState<BudgetPerdioDisplay>(
    BudgetPerdioDisplay.MONTHLY,
  );

  const breakdown = useMemo(
    () =>
      getCategoriesBreakdown(
        budget,
        budget?.totalAmount,
        display,
        fractionCount,
        property.fractionCount,
      ),
    [budget, display, fractionCount, property.fractionCount],
  );

  if (breakdown.length === 0) {
    return <BudgetNotFoundCard />;
  }

  return (
    <PageBody>
      <Flexbox
        direction="column"
        rowGap={spacing.rowGap}
        alignContent="stretch"
      >
        <Flexbox direction="column" alignItems="center">
          <Text className={css(styles.title)}>{`${budget.year} Budget`}</Text>
          <Text
            type="secondary"
            className={css(styles.subtitle)}
          >{`${fractionCount} of ${property.fractionCount} shares`}</Text>
        </Flexbox>
        {!theme.md && (
          <Flexbox className={css(styles.selectorContainer)}>
            <WalletBudgetBreakdownDisplaySelector
              value={display}
              onChange={setDisplay}
            />
          </Flexbox>
        )}
        <Row gutter={[60, 16]} align="middle">
          <Col md={12} xs={24}>
            <WalletBudgetBreakdownChart breakdown={breakdown} />
          </Col>
          <Col md={12} xs={24}>
            {theme.md && (
              <WalletBudgetBreakdownDisplaySelector
                value={display}
                onChange={setDisplay}
              />
            )}
            <WalletBudgetBreakdownTable breakdown={breakdown} />
          </Col>
        </Row>
      </Flexbox>
    </PageBody>
  );
}

function BudgetNotFoundCard() {
  return (
    <PageBody>
      <Flexbox justifyContent="center">
        <Text>We do not have a budget ready for this property yet</Text>
      </Flexbox>
    </PageBody>
  );
}

export function WalletBudgetBreakdownLoading() {
  const spacing = useGridSpacing();
  const { xs } = useBreakpoint();

  return (
    <PageBody>
      <Flexbox
        direction="column"
        rowGap={spacing.rowGap}
        alignContent="stretch"
      >
        <Flexbox direction="column" alignItems="center" rowGap="10px">
          <Skeleton.Input active style={{ width: '200px' }} />
          <Skeleton.Input active style={{ width: '100px' }} />
        </Flexbox>
        {xs && (
          <Flexbox justifyContent="center">
            <Skeleton.Input active style={{ width: '250px' }} />
          </Flexbox>
        )}
        <Row gutter={[60, 16]} align="middle">
          <Col md={12} xs={24}>
            <Flexbox justifyContent="center">
              <Skeleton.Avatar
                style={{ width: '300px', height: '300px' }}
                active
              />
            </Flexbox>
          </Col>
          <Col md={12} xs={24}>
            {!xs && <Skeleton.Input active style={{ width: '100px' }} />}
            <Skeleton active />
          </Col>
        </Row>
      </Flexbox>
    </PageBody>
  );
}

export default WalletBudgetBreakdown;
