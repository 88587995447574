/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type users_set_input = {
    active?: boolean | null | undefined;
    address_id?: string | null | undefined;
    created_at?: string | null | undefined;
    email?: string | null | undefined;
    first_name?: string | null | undefined;
    last_name?: string | null | undefined;
    phone?: string | null | undefined;
    updated_at?: string | null | undefined;
    user_id?: string | null | undefined;
};
export type ProfilePhoneMutationVariables = {
    user_id: string;
    data?: users_set_input | null | undefined;
};
export type ProfilePhoneMutationResponse = {
    readonly update_users_by_pk: {
        readonly id: string;
        readonly phone: string | null;
    } | null;
};
export type ProfilePhoneMutation = {
    readonly response: ProfilePhoneMutationResponse;
    readonly variables: ProfilePhoneMutationVariables;
};



/*
mutation ProfilePhoneMutation(
  $user_id: uuid!
  $data: users_set_input
) {
  update_users_by_pk(pk_columns: {user_id: $user_id}, _set: $data) {
    id
    phone
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "data"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "data"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "user_id",
            "variableName": "user_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "update_users_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePhoneMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfilePhoneMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6c777a868fc7d8dacc882717774e11b8",
    "id": null,
    "metadata": {},
    "name": "ProfilePhoneMutation",
    "operationKind": "mutation",
    "text": "mutation ProfilePhoneMutation(\n  $user_id: uuid!\n  $data: users_set_input\n) {\n  update_users_by_pk(pk_columns: {user_id: $user_id}, _set: $data) {\n    id\n    phone\n  }\n}\n"
  }
};
})();
(node as any).hash = '07e9bc96801aea01fb411a704fccb835';
export default node;
