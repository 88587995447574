import type { AppFelaStyle, AppFelaTheme } from './AppThemeProvider';

import { createRenderer } from 'fela';
import React from 'react';
import { RendererProvider, useFela } from 'react-fela';

import './fonts.css';
import '../__generated__/styles/antd.css';
import AppThemeProvider from './AppThemeProvider';

const styles: Record<string, AppFelaStyle> = {
  antdOverrides: ({ theme }) => ({
    '& .ant-card': {
      overflow: 'hidden',
    },
    '& .ant-btn': {
      fontSize: '14px',
      textTransform: 'uppercase',
      letterSpacing: '3px',
    },
    '& .ant-input:focus': {
      borderColor: theme.ligthColor,
      boxShadow: 'none',
    },
    '& .ant-input:hover': {
      borderColor: theme.ligthColor,
    },
    '& a:hover': {
      color: theme.ligthColor,
    },
    '& a:hover .anticon': {
      color: theme.ligthColor,
    },
    '& .ant-menu-horizontal > .ant-menu-item a:hover': {
      color: theme.ligthColor,
    },
    '& .ant-tabs-tab:hover': {
      color: theme.ligthColor,
    },
  }),
};

const renderer = createRenderer();

type Props = {
  children?: React.ReactNode;
};

function Styles({ children }: Props) {
  return (
    <RendererProvider renderer={renderer}>
      <AppThemeProvider>
        <AntDOverrides>{children}</AntDOverrides>
      </AppThemeProvider>
    </RendererProvider>
  );
}

function AntDOverrides({ children }: Props): JSX.Element {
  const { css } = useFela<AppFelaTheme>();

  return <div className={css(styles.antdOverrides)}>{children}</div>;
}

export default Styles;
