import type { ProfileAvatarFragment$key } from '../../__generated__/relay/ProfileAvatarFragment.graphql';
import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFela } from 'react-fela';
import { useFragment } from 'react-relay/hooks';

import Flexbox from '../core/Flexbox';

const styles: Record<string, AppFelaStyle> = {
  initials: ({ theme }) => ({
    width: '105px',
    height: '105px',
    backgroundColor: theme.primaryColor,
    color: '#FFF',
    fontSize: '40px',
    borderRadius: '50%',
  }),
};

const fragment = graphql`
  fragment ProfileAvatarFragment on users {
    id
    firstName: first_name
    lastName: last_name
  }
`;

type Props = {
  fragmentRef: ProfileAvatarFragment$key;
};

function ProfileAvatar({ fragmentRef }: Props): JSX.Element {
  const { firstName, lastName } = useFragment(fragment, fragmentRef);
  const { css } = useFela<AppFelaTheme>();

  return (
    <Flexbox
      className={css(styles.initials)}
      justifyContent="center"
      alignItems="center"
    >
      {firstName?.[0]}
      {lastName?.[0]}
    </Flexbox>
  );
}

export default ProfileAvatar;
