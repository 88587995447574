/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyGuidelinesCardFragment = {
    readonly id: string;
    readonly manuals: ReadonlyArray<{
        readonly id: string;
        readonly manual: {
            readonly id: string;
            readonly title: string;
            readonly description: string;
        };
    }>;
    readonly " $refType": "PropertyGuidelinesCardFragment";
};
export type PropertyGuidelinesCardFragment$data = PropertyGuidelinesCardFragment;
export type PropertyGuidelinesCardFragment$key = {
    readonly " $data"?: PropertyGuidelinesCardFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyGuidelinesCardFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyGuidelinesCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "order": "asc"
          }
        }
      ],
      "concreteType": "property_manual_categories_manuals",
      "kind": "LinkedField",
      "name": "manuals",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "manuals",
          "kind": "LinkedField",
          "name": "manual",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": "description",
              "args": null,
              "kind": "ScalarField",
              "name": "description_rich",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "manuals(order_by:{\"order\":\"asc\"})"
    }
  ],
  "type": "property_manual_categories",
  "abstractKey": null
};
})();
(node as any).hash = 'cee0343d5560e0c6352e424ec6cd3db4';
export default node;
