import type { WalletHighlightSectionFragment$key } from '../../__generated__/relay/WalletHighlightSectionFragment.graphql';

import { Skeleton } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useFragment } from 'react-relay/hooks';

import useGridSpacing from '../../hooks/useGridSpacing';
import useConciergeHref from '../../utils/useConciergeHref';
import Button from '../core/Button';
import Flexbox from '../core/Flexbox';
import HighlightSection from '../HighlightSection';
import SectionHeader from '../SectionHeader';
import WalletUpcomingPaymentCard, {
  WalletUpcomingPaymentCardLoading,
} from './WalletUpcomingPaymentCard';

const fragment = graphql`
  fragment WalletHighlightSectionFragment on property_fractions {
    ...WalletUpcomingPaymentCardFragment
    ...useConciergeHrefFragment
  }
`;

type Props = {
  fragmentRef: WalletHighlightSectionFragment$key;
};

function WalletHighlightSection({ fragmentRef }: Props): JSX.Element {
  const fraction = useFragment(fragment, fragmentRef);
  const conciergeHref = useConciergeHref(fraction);
  const spacing = useGridSpacing();

  return (
    <HighlightSection>
      <Flexbox direction="column" alignItems="stretch" rowGap={spacing.rowGap}>
        <Flexbox direction="column">
          <SectionHeader title="Upcoming Payment" />
          <WalletUpcomingPaymentCard fragmentRef={fraction} />
        </Flexbox>
        {conciergeHref && (
          <Flexbox justifyContent="center">
            <Button href={conciergeHref}>Contact Concierge</Button>
          </Flexbox>
        )}
      </Flexbox>
    </HighlightSection>
  );
}

export function WalletHighlightSectionLoading() {
  const spacing = useGridSpacing();

  return (
    <HighlightSection>
      <Flexbox direction="column" alignItems="stretch" rowGap={spacing.rowGap}>
        <Flexbox direction="column">
          <SectionHeader title="Upcoming Payment" />
          <WalletUpcomingPaymentCardLoading />
        </Flexbox>
        <Flexbox justifyContent="center">
          <Skeleton.Input active style={{ width: '100px' }} />
        </Flexbox>
      </Flexbox>
    </HighlightSection>
  );
}

export default WalletHighlightSection;
