import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

type Spacing = {
  x: number;
  y: number;
  rowGap: string;
  columnGap: string;
};

const useGridSpacing = (): Spacing => {
  const { xs } = useBreakpoint();
  return {
    x: xs ? 16 : 24,
    y: xs ? 16 : 24,
    rowGap: xs ? '24px' : '50px',
    columnGap: xs ? '16px' : '30px',
  };
};

export default useGridSpacing;
