/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyBannerCardFragment = {
    readonly property: {
        readonly imageUrl: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"PropertyHotDataCardFragment">;
    readonly " $refType": "PropertyBannerCardFragment";
};
export type PropertyBannerCardFragment$data = PropertyBannerCardFragment;
export type PropertyBannerCardFragment$key = {
    readonly " $data"?: PropertyBannerCardFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyBannerCardFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyBannerCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "imageUrl",
          "args": null,
          "kind": "ScalarField",
          "name": "image_url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PropertyHotDataCardFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'f6dcf93bb417e765fdab36e949895c00';
export default node;
