import type { WalletQuery } from '../__generated__/relay/WalletQuery.graphql';

import graphql from 'babel-plugin-relay/macro';
import nullthrows from 'nullthrows';
import React, { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';

import Flexbox from '../components/core/Flexbox';
import ErrorBoundary from '../components/ErrorBoundary';
import WalletBudgetBreakdown, {
  WalletBudgetBreakdownLoading,
} from '../components/wallet/WalletBudgetBreakdown';
import WalletFooterImage from '../components/wallet/WalletFooterImage';
import WalletHighlightSection, {
  WalletHighlightSectionLoading,
} from '../components/wallet/WalletHighlightSection';
import { useActivePropertyFractionId } from '../contexts/UserPreferencesContext';
import useBudgetYear from '../hooks/useBudgetYear';

const query = graphql`
  query WalletQuery($id: ID!, $budgetYear: Int!) {
    fraction: node(id: $id) {
      id
      __typename
      ... on property_fractions {
        id
        ...WalletHighlightSectionFragment
        ...WalletBudgetBreakdownFragment
      }
    }
  }
`;

function Wallet() {
  const budgetYear = useBudgetYear();
  const fractionId = nullthrows(
    useActivePropertyFractionId(),
    'Page needs fraction id to show details',
  );
  const data = useLazyLoadQuery<WalletQuery>(query, {
    id: fractionId,
    budgetYear,
  });
  const fraction = nullthrows(data.fraction);

  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <WalletHighlightSection fragmentRef={fraction} />
      <WalletBudgetBreakdown fragmentRef={fraction} />
      <WalletFooterImage />
    </Flexbox>
  );
}

function WalletWrapper(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Wallet />
      </Suspense>
    </ErrorBoundary>
  );
}

function Loading(): JSX.Element {
  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <WalletHighlightSectionLoading />
      <WalletBudgetBreakdownLoading />
      <WalletFooterImage />
    </Flexbox>
  );
}

export default WalletWrapper;
