import type { FelaStyle } from 'react-fela';

import { Col, Row, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from '../components/core/Flexbox';

const { Link, Text, Title } = Typography;

type StyleProps = {
  xs: boolean;
};

const styles: Record<string, FelaStyle<{}, StyleProps>> = {
  centeredRow: {
    marginTop: '20%',
  },
  image: {
    width: '100%',
  },
  link: {
    textDecoration: 'underline !important',
  },
  textStyle: ({ xs }) => ({
    textAlign: xs ? 'center' : 'start',
  }),
};

function PageNotFound(): JSX.Element {
  const { xs } = useBreakpoint();
  const { css } = useFela<{}, StyleProps>({ xs: xs === true });

  return (
    <Row
      gutter={[70, 70]}
      justify="center"
      align="middle"
      className={css(styles.centeredRow)}
    >
      <Col>
        <img
          src="/images/lost_bottle.svg"
          className={css(styles.image)}
          alt=""
        />
      </Col>
      <Col>
        <Flexbox
          direction="column"
          alignItems={xs ? 'center' : 'start'}
          rowGap="5px"
        >
          <Title>Error 404</Title>
          <Text className={css(styles.textStyle)}>
            Oops! We are having trouble finding your page.
          </Text>
          <Text>
            Try again later or{' '}
            <Link className={css(styles.link)} href="mailto:info@kocomo.com">
              contact us
            </Link>
          </Text>
        </Flexbox>
      </Col>
    </Row>
  );
}

export default PageNotFound;
