import type { FelaStyle } from 'react-fela';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { useFela } from 'react-fela';

import Flexbox from './core/Flexbox';

type StyleProps = {
  xs: boolean;
};

const styles: Record<string, FelaStyle<{}, StyleProps>> = {
  highlight: ({ xs }) => ({
    backgroundColor: '#EBEEEB',
    padding: xs ? '16px' : '50px',
    borderRadius: xs ? '16px' : '30px',
    width: '100%',
  }),
  innerContent: {
    width: '575px',
    maxWidth: '100%',
  },
};

export default function HighlightSection(props: {
  children: React.ReactChild;
}) {
  const { children } = props;
  const { xs } = useBreakpoint();
  const { css } = useFela<{}, StyleProps>({ xs: xs === true });

  return (
    <Flexbox className={css(styles.highlight)} justifyContent="center">
      <Flexbox className={css(styles.innerContent)} direction="column">
        {children}
      </Flexbox>
    </Flexbox>
  );
}
