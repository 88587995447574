const addThousandsSeparator = (
  value: string,
  thousandSeparator: string,
  decimal: string,
) => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  return parts.join(decimal);
};

type Options = Readonly<{
  symbol?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  fixedDecimals?: number;
}>;

const formatCurrency = (
  value: number,
  {
    symbol = '$',
    thousandSeparator = ',',
    decimalSeparator = '.',
    fixedDecimals = 0,
  }: Options = {},
): string =>
  `${symbol} ${addThousandsSeparator(
    value.toFixed(fixedDecimals),
    thousandSeparator,
    decimalSeparator,
  )}`;

export default formatCurrency;
