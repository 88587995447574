import type { AuthError } from 'firebase/auth';
import type { FelaStyle } from 'react-fela';

import { Button, Form, Input, Typography } from 'antd';
import { AuthErrorCodes } from 'firebase/auth';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';

import Labeled from '../../components/Labeled';
import useSignInWithEmailAndPassword from '../../hooks/useSignInWithEmailAndPassword';

type FormItemRule = React.ComponentProps<typeof Form.Item>['rules'];
type LoginFormValues = {
  email: string;
  password: string;
};

const { Title, Paragraph } = Typography;

const styles: Record<string, FelaStyle<{}>> = {
  title: {
    textAlign: 'center',
    marginBottom: '40px !important',
    fontWeight: '200 !important',
  },
  button: {
    marginBottom: '15px',
  },
  centered: {
    textAlign: 'center',
  },
  link: {
    fontSize: '14px',
  },
};

const RULES: Record<string, FormItemRule> = {
  email: [
    { type: 'email', message: 'The input is not valid e-mail' },
    { required: true, message: 'Please input your e-mail' },
  ],
  password: [{ required: true, message: 'Please input your password' }],
};

const getErrorMessage = (error: AuthError) => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_EMAIL:
      return 'Email address is not valid';
    case AuthErrorCodes.USER_DISABLED:
      return 'User is disabled';
    case AuthErrorCodes.USER_DELETED:
      return 'User not found';
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'Wrong password';
    default:
      return 'There was an error, please try again later';
  }
};

function LoginEmailPasswordPage(): JSX.Element {
  const { css } = useFela();

  const [signInWithEmailAndPassword, loading, error] =
    useSignInWithEmailAndPassword();

  const loginHandler = useCallback(
    (values: LoginFormValues) => {
      const { email, password } = values;
      signInWithEmailAndPassword(email, password);
    },
    [signInWithEmailAndPassword],
  );

  return (
    <Form name="login" onFinish={loginHandler} validateTrigger="onSubmit">
      <Title level={2} className={css(styles.title)}>
        Log in to continue
      </Title>
      <Labeled title="Email">
        <Form.Item name="email" rules={RULES.email}>
          <Input />
        </Form.Item>
      </Labeled>
      <Labeled title="Password">
        <Form.Item name="password" rules={RULES.password}>
          <Input.Password />
        </Form.Item>
      </Labeled>
      {error && (
        <Paragraph type="danger" className={css(styles.centered)}>
          {getErrorMessage(error)}
        </Paragraph>
      )}
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={loading}
        className={css(styles.button)}
        block
      >
        LOG IN
      </Button>
      <Paragraph className={css(styles.centered)}>
        <Link className={css(styles.link)} to="/auth/forgot">
          Forgot password?
        </Link>
      </Paragraph>
    </Form>
  );
}

export default LoginEmailPasswordPage;
