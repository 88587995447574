/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useConciergeHrefFragment = {
    readonly property: {
        readonly conciergeWhatsapp: string | null;
    };
    readonly " $refType": "useConciergeHrefFragment";
};
export type useConciergeHrefFragment$data = useConciergeHrefFragment;
export type useConciergeHrefFragment$key = {
    readonly " $data"?: useConciergeHrefFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useConciergeHrefFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useConciergeHrefFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "conciergeWhatsapp",
          "args": null,
          "kind": "ScalarField",
          "name": "concierge_whatsapp",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '584f99f07ac082733de68935305b3138';
export default node;
