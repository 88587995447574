import type { CalendarQuery } from '../__generated__/relay/CalendarQuery.graphql';
import type { BookingType } from '../components/calendar/CalendarBookingTypeCheckboxGroup';

import graphql from 'babel-plugin-relay/macro';
import nullthrows from 'nullthrows';
import React, { Suspense, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';

import CalendarFooterImage from '../components/calendar/CalendarFooterImage';
import CalendarHighlightSection, {
  CalendarHighlightSectionLoading,
} from '../components/calendar/CalendarHighlightSection';
import CalendarYearBookings from '../components/calendar/CalendarYearBookings';
import Flexbox from '../components/core/Flexbox';
import ErrorBoundary from '../components/ErrorBoundary';
import { useActivePropertyFractionId } from '../contexts/UserPreferencesContext';

const query = graphql`
  query CalendarQuery($id: ID!) {
    fraction: node(id: $id) {
      id
      __typename
      ... on property_fractions {
        ...CalendarHighlightSectionFragment
        ...CalendarYearBookingsFragment
      }
    }
  }
`;

function Calendar() {
  const fractionId = nullthrows(
    useActivePropertyFractionId(),
    'Page needs fraction id to show details',
  );
  const data = useLazyLoadQuery<CalendarQuery>(query, {
    id: fractionId,
  });
  const fraction = nullthrows(data.fraction);
  const [selectedBookingTypes, setSelectedBookingTypes] = useState<
    BookingType[]
  >(['STAY']);

  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <CalendarHighlightSection
        fragmentRef={fraction}
        selectedBookingTypes={selectedBookingTypes}
        setSelectedBookingTypes={setSelectedBookingTypes}
      />
      <CalendarYearBookings
        filterBookingTypes={selectedBookingTypes}
        fragmentRef={fraction}
      />
      <CalendarFooterImage />
    </Flexbox>
  );
}

function CalendarWrapper(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Calendar />
      </Suspense>
    </ErrorBoundary>
  );
}

function Loading(): JSX.Element {
  return (
    <Flexbox direction="column" alignItems="center" rowGap="40px">
      <CalendarHighlightSectionLoading />
      <CalendarFooterImage />
    </Flexbox>
  );
}

export default CalendarWrapper;
