/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyHotDataConciergeItemFragment = {
    readonly property: {
        readonly conciergeName: string | null;
        readonly conciergeWhatsapp: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"useConciergeHrefFragment">;
    readonly " $refType": "PropertyHotDataConciergeItemFragment";
};
export type PropertyHotDataConciergeItemFragment$data = PropertyHotDataConciergeItemFragment;
export type PropertyHotDataConciergeItemFragment$key = {
    readonly " $data"?: PropertyHotDataConciergeItemFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyHotDataConciergeItemFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyHotDataConciergeItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "conciergeName",
          "args": null,
          "kind": "ScalarField",
          "name": "concierge_name",
          "storageKey": null
        },
        {
          "alias": "conciergeWhatsapp",
          "args": null,
          "kind": "ScalarField",
          "name": "concierge_whatsapp",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useConciergeHrefFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'd047b32bb424a03fe67b7c8d6115fb23';
export default node;
