import type { AppFelaStyle, AppFelaTheme } from '../../styles/AppThemeProvider';

import { Button as AntButton } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';

const styles: Record<string, AppFelaStyle> = {
  button: ({ theme }) => ({
    fontSize: theme.fontSizeSmall,
    height: theme.xs ? '32px' : '44px',
    '& .ant-btn:not([disabled]):hover': {
      background: theme.ligthColor,
      border: 'none',
      color: '#fff',
    },
    ':hover': {
      background: theme.ligthColor,
      border: '#fff',
      color: '#fff',
    },
  }),
};

type Props = React.ComponentProps<typeof AntButton>;

function Button({
  href,
  target,
  children,
  className: classNameProp,
  ...rest
}: Props) {
  const { css } = useFela<AppFelaTheme>();

  const classNameCombined = [css(styles.button), classNameProp]
    .filter(Boolean)
    .join(' ');

  let content = children;
  if (href != null) {
    if (href.startsWith('http')) {
      content = (
        <a href={href} target={target || '_blank'} rel="noreferrer">
          {children}
        </a>
      );
    } else {
      content = (
        <Link to={href} target={target}>
          {children}
        </Link>
      );
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AntButton className={classNameCombined} {...rest}>
      {content}
    </AntButton>
  );
}

export default Button;
