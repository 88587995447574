import type { FelaStyle } from 'react-fela';

import { Layout, Row, Col } from 'antd';
import React from 'react';
import { useFela } from 'react-fela';

const { Content } = Layout;

const styles: Record<string, FelaStyle<{}>> = {
  layout: {
    backgroundColor: '#e5e5e5',
  },
  centeredRow: {
    minHeight: '100vh',
    paddingLeft: '30px',
  },
  logoWrapper: {
    maxWidth: '50%',
  },
  logo: {
    width: '100%',
    maxWidth: '250px',
  },
};

function LoadingLayout(): JSX.Element {
  const { css } = useFela();

  return (
    <Layout className={css(styles.layout)}>
      <Content>
        <Row
          justify="center"
          align="middle"
          className={css(styles.centeredRow)}
        >
          <Col className={css(styles.logoWrapper)}>
            <img
              src="/images/coconut_straw_animation.svg"
              className={css(styles.logo)}
              alt="Kocomo"
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default LoadingLayout;
