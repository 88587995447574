/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type expense_categories_enum = "HOA" | "HOUSEKEEPING" | "INSURANCE" | "MAINTENANCE" | "PROPERTY_MANAGEMENT" | "PROPERTY_TAXES" | "REPAIRS_RESERVE" | "UTILITIES" | "%future added value";
export type WalletBudgetBreakdownFragment = {
    readonly fractionCount: number;
    readonly property: {
        readonly fractionCount: number;
        readonly budgets: ReadonlyArray<{
            readonly year: number;
            readonly totalAmount: number | null;
            readonly budgetDetails: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly category: expense_categories_enum;
                readonly amount: number;
            }>;
        }>;
    };
    readonly " $refType": "WalletBudgetBreakdownFragment";
};
export type WalletBudgetBreakdownFragment$data = WalletBudgetBreakdownFragment;
export type WalletBudgetBreakdownFragment$key = {
    readonly " $data"?: WalletBudgetBreakdownFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WalletBudgetBreakdownFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "budgetYear"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletBudgetBreakdownFragment",
  "selections": [
    {
      "alias": "fractionCount",
      "args": null,
      "kind": "ScalarField",
      "name": "fraction_count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "fractionCount",
          "args": null,
          "kind": "ScalarField",
          "name": "total_fraction_count",
          "storageKey": null
        },
        {
          "alias": "budgets",
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "_eq",
                      "variableName": "budgetYear"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "year"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "annual_budgets",
          "kind": "LinkedField",
          "name": "annual_budgets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "year",
              "storageKey": null
            },
            {
              "alias": "totalAmount",
              "args": null,
              "kind": "ScalarField",
              "name": "total_amount",
              "storageKey": null
            },
            {
              "alias": "budgetDetails",
              "args": null,
              "concreteType": "annual_budget_details",
              "kind": "LinkedField",
              "name": "annual_budget_details",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "category",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '0565398e4340466faa744e2040d67613';
export default node;
