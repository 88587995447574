import type { UserPreferencesContextProviderFragment$key } from '../__generated__/relay/UserPreferencesContextProviderFragment.graphql';

import graphql from 'babel-plugin-relay/macro';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useFragment } from 'react-relay/hooks';

import assertInArrayOrFirst from '../utils/assertInArrayOrFirst';
import emptyFunction from '../utils/emptyFunction';
import orEmptyArray from '../utils/orEmptyArray';
import { useUserLocalStorage } from '../utils/userLocalStorage';

type UserPreferencesContextValue = {
  activePropertyFractionId: string | null;
  setActivePropertyFractionId: (fractionId: string) => void;
};

const fragment = graphql`
  fragment UserPreferencesContextProviderFragment on users {
    id
    userId: user_id
    fractions: property_fractions {
      id
    }
  }
`;

const initial: UserPreferencesContextValue = {
  activePropertyFractionId: null,
  setActivePropertyFractionId: emptyFunction,
};

const UserPreferencesContext = createContext(initial);

type Props = Readonly<{
  children: React.ReactNode;
  fragmentRef: UserPreferencesContextProviderFragment$key;
}>;

export function UserPreferencesContextProvider({
  children,
  fragmentRef,
}: Props) {
  const user = useFragment(fragment, fragmentRef);
  const fractionIds = orEmptyArray(user.fractions).map(
    (fraction) => fraction.id,
  );
  const userLocalStorage = useUserLocalStorage(user.id);

  const initialActivePropertyFractionIdSelected = assertInArrayOrFirst(
    userLocalStorage.getItem('activePropertyFractionId'),
    fractionIds,
  );
  const [activePropertyFractionId, setActivePropertyFractionId] = useState<
    string | null
  >(initialActivePropertyFractionIdSelected);

  const contextValue = useMemo(
    () => ({
      activePropertyFractionId,
      setActivePropertyFractionId: (newPropertyId: string) => {
        setActivePropertyFractionId(newPropertyId);
        userLocalStorage.setItem('activePropertyFractionId', newPropertyId);
      },
    }),
    [activePropertyFractionId, userLocalStorage],
  );
  return (
    <UserPreferencesContext.Provider value={contextValue}>
      {children}
    </UserPreferencesContext.Provider>
  );
}

export function useActivePropertyFractionId(): string | null {
  return useContext(UserPreferencesContext).activePropertyFractionId;
}

export function useSetActivePropertyFractionId(): (fractionId: string) => void {
  return useContext(UserPreferencesContext).setActivePropertyFractionId;
}
