/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CalendarQueryVariables = {
    id: string;
};
export type CalendarQueryResponse = {
    readonly fraction: {
        readonly id: string;
        readonly __typename: string;
        readonly " $fragmentRefs": FragmentRefs<"CalendarHighlightSectionFragment" | "CalendarYearBookingsFragment">;
    } | null;
};
export type CalendarQuery = {
    readonly response: CalendarQueryResponse;
    readonly variables: CalendarQueryVariables;
};



/*
query CalendarQuery(
  $id: ID!
) {
  fraction: node(id: $id) {
    id
    __typename
    ... on property_fractions {
      ...CalendarHighlightSectionFragment
      ...CalendarYearBookingsFragment
    }
  }
}

fragment CalendarBookingsCardFragment on property_fractions {
  ...CalendarBookingsTableFragment
}

fragment CalendarBookingsTableFragment on property_fractions {
  bookings(order_by: {date_start: asc}) {
    id
    dateStart: date_start
    dateEnd: date_end
    weeksCount: weeks_count
    bookingType: booking_type
  }
}

fragment CalendarHighlightSectionFragment on property_fractions {
  ...CalendarBookingsCardFragment
  ...useConciergeHrefFragment
}

fragment CalendarYearBookingsFragment on property_fractions {
  bookings(order_by: {date_start: asc}) {
    id
    dateStart: date_start
    dateEnd: date_end
    weeksCount: weeks_count
    bookingType: booking_type
  }
}

fragment useConciergeHrefFragment on property_fractions {
  property {
    conciergeWhatsapp: concierge_whatsapp
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CalendarQuery",
    "selections": [
      {
        "alias": "fraction",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CalendarHighlightSectionFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CalendarYearBookingsFragment"
              }
            ],
            "type": "property_fractions",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CalendarQuery",
    "selections": [
      {
        "alias": "fraction",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "order_by",
                    "value": {
                      "date_start": "asc"
                    }
                  }
                ],
                "concreteType": "bookings",
                "kind": "LinkedField",
                "name": "bookings",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "dateStart",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date_start",
                    "storageKey": null
                  },
                  {
                    "alias": "dateEnd",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date_end",
                    "storageKey": null
                  },
                  {
                    "alias": "weeksCount",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeks_count",
                    "storageKey": null
                  },
                  {
                    "alias": "bookingType",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "booking_type",
                    "storageKey": null
                  }
                ],
                "storageKey": "bookings(order_by:{\"date_start\":\"asc\"})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "properties",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  {
                    "alias": "conciergeWhatsapp",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "concierge_whatsapp",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "property_fractions",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c6076021049d3cc9141efee96d87faf",
    "id": null,
    "metadata": {},
    "name": "CalendarQuery",
    "operationKind": "query",
    "text": "query CalendarQuery(\n  $id: ID!\n) {\n  fraction: node(id: $id) {\n    id\n    __typename\n    ... on property_fractions {\n      ...CalendarHighlightSectionFragment\n      ...CalendarYearBookingsFragment\n    }\n  }\n}\n\nfragment CalendarBookingsCardFragment on property_fractions {\n  ...CalendarBookingsTableFragment\n}\n\nfragment CalendarBookingsTableFragment on property_fractions {\n  bookings(order_by: {date_start: asc}) {\n    id\n    dateStart: date_start\n    dateEnd: date_end\n    weeksCount: weeks_count\n    bookingType: booking_type\n  }\n}\n\nfragment CalendarHighlightSectionFragment on property_fractions {\n  ...CalendarBookingsCardFragment\n  ...useConciergeHrefFragment\n}\n\nfragment CalendarYearBookingsFragment on property_fractions {\n  bookings(order_by: {date_start: asc}) {\n    id\n    dateStart: date_start\n    dateEnd: date_end\n    weeksCount: weeks_count\n    bookingType: booking_type\n  }\n}\n\nfragment useConciergeHrefFragment on property_fractions {\n  property {\n    conciergeWhatsapp: concierge_whatsapp\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0e1f5c49c51a2ec2fd10e3d66ece75db';
export default node;
