import type { MyHomeQuery } from '../__generated__/relay/MyHomeQuery.graphql';

import { Card, Col, Row, Skeleton } from 'antd';
import graphql from 'babel-plugin-relay/macro';
import dayjs from 'dayjs';
import nullthrows from 'nullthrows';
import React, { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Flexbox from '../components/core/Flexbox';
import ErrorBoundary from '../components/ErrorBoundary';
import PropertyBannerCard from '../components/property/PropertyBannerCard';
import PropertyFooterImage from '../components/property/PropertyFooterImage';
import PropertyGuidelinesSection from '../components/property/PropertyGuidelinesSection';
import { useActivePropertyFractionId } from '../contexts/UserPreferencesContext';
import useGridSpacing from '../hooks/useGridSpacing';

const query = graphql`
  query MyHomeQuery($id: ID!, $today: date!) {
    fraction: node(id: $id) {
      id
      __typename
      ... on property_fractions {
        ...PropertyBannerCardFragment
        ...PropertyGuidelinesSectionFragment
      }
    }
  }
`;

function MyHome() {
  const fractionId = nullthrows(
    useActivePropertyFractionId(),
    'Page needs fraction id to show details',
  );
  const data = useLazyLoadQuery<MyHomeQuery>(query, {
    id: fractionId,
    today: dayjs().format('YYYY-MM-DD'),
  });
  const fraction = nullthrows(data.fraction);

  return (
    <>
      <PropertyBannerCard fragmentRef={fraction} />
      <PropertyGuidelinesSection fragmentRef={fraction} />
      <PropertyFooterImage />
    </>
  );
}

function MyHomeWrapper(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <MyHome />
      </Suspense>
    </ErrorBoundary>
  );
}

function Loading(): JSX.Element {
  const spacing = useGridSpacing();
  return (
    <Row gutter={[spacing.x, spacing.y]}>
      <Col xs={24}>
        <Card>
          <Flexbox direction="column" rowGap="50px">
            <Skeleton.Input active style={{ width: '400px' }} />
            <Skeleton.Input active style={{ width: '400px' }} />
            <Skeleton.Input active style={{ width: '400px' }} />
            <Skeleton.Input active style={{ width: '400px' }} />
          </Flexbox>
        </Card>
      </Col>
      <Col xs={24}>
        <Row gutter={[spacing.x, spacing.y]}>
          <Col xs={24}>
            <Card>
              <Skeleton active title={false} paragraph={{ rows: 3 }} />
            </Card>
          </Col>
          <Col xs={24}>
            <Card>
              <Skeleton active title={false} paragraph={{ rows: 3 }} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default MyHomeWrapper;
