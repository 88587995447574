/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PropertyGuidelinesSectionFragment = {
    readonly property: {
        readonly categories: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
            readonly " $fragmentRefs": FragmentRefs<"PropertyGuidelinesCardFragment">;
        }>;
    };
    readonly " $refType": "PropertyGuidelinesSectionFragment";
};
export type PropertyGuidelinesSectionFragment$data = PropertyGuidelinesSectionFragment;
export type PropertyGuidelinesSectionFragment$key = {
    readonly " $data"?: PropertyGuidelinesSectionFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PropertyGuidelinesSectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PropertyGuidelinesSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": "categories",
          "args": [
            {
              "kind": "Literal",
              "name": "order_by",
              "value": {
                "order": "asc"
              }
            }
          ],
          "concreteType": "property_manual_categories",
          "kind": "LinkedField",
          "name": "property_manual_categories",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PropertyGuidelinesCardFragment"
            }
          ],
          "storageKey": "property_manual_categories(order_by:{\"order\":\"asc\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = '9cba6ea91c8e4d3c3d72596edba6dfa2';
export default node;
