import { useMemo } from 'react';

const getUserKey = (userId: string, key: string): string => `${userId}/${key}`;

export const setItem = (userId: string, key: string, value: string) =>
  localStorage.setItem(getUserKey(userId, key), value);

export const getItem = (userId: string, key: string): string | null =>
  localStorage.getItem(getUserKey(userId, key));

export function useUserLocalStorage(userId: string) {
  return useMemo(
    () => ({
      setItem: (key: string, value: string) => setItem(userId, key, value),
      getItem: (key: string) => getItem(userId, key),
    }),
    [userId],
  );
}
