/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type WalletUpcomingPaymentCardFragment = {
    readonly fractionCount: number;
    readonly monthlyFee: number;
    readonly property: {
        readonly name: string;
        readonly fractionCount: number;
        readonly budgets: ReadonlyArray<{
            readonly totalAmount: number | null;
        }>;
    };
    readonly " $fragmentRefs": FragmentRefs<"useNextPaymentDateFragment">;
    readonly " $refType": "WalletUpcomingPaymentCardFragment";
};
export type WalletUpcomingPaymentCardFragment$data = WalletUpcomingPaymentCardFragment;
export type WalletUpcomingPaymentCardFragment$key = {
    readonly " $data"?: WalletUpcomingPaymentCardFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WalletUpcomingPaymentCardFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "budgetYear"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletUpcomingPaymentCardFragment",
  "selections": [
    {
      "alias": "fractionCount",
      "args": null,
      "kind": "ScalarField",
      "name": "fraction_count",
      "storageKey": null
    },
    {
      "alias": "monthlyFee",
      "args": null,
      "kind": "ScalarField",
      "name": "monthly_fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "properties",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": "fractionCount",
          "args": null,
          "kind": "ScalarField",
          "name": "total_fraction_count",
          "storageKey": null
        },
        {
          "alias": "budgets",
          "args": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "_eq",
                      "variableName": "budgetYear"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "year"
                }
              ],
              "kind": "ObjectValue",
              "name": "where"
            }
          ],
          "concreteType": "annual_budgets",
          "kind": "LinkedField",
          "name": "annual_budgets",
          "plural": true,
          "selections": [
            {
              "alias": "totalAmount",
              "args": null,
              "kind": "ScalarField",
              "name": "total_amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNextPaymentDateFragment"
    }
  ],
  "type": "property_fractions",
  "abstractKey": null
};
(node as any).hash = 'd9de73e43855d2eaddc7346b19079d8e';
export default node;
