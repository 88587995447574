import { useCallback, useMemo, useReducer } from 'react';

export type LoadingHook<T, E> = [T | null, boolean, E | null];

export type LoadingValue<T, E> = {
  error: E | null;
  loading: boolean;
  value: T | null;
  setError: (error: E) => void;
  setValue: (value?: T) => void;
  reset: () => void;
};

type ReducerState<E> = {
  error: E | null;
  loading: boolean;
  value: any;
};

type ErrorAction<E> = { type: 'error'; error: E };
type ResetAction = { type: 'reset'; defaultValue?: any };
type ValueAction = { type: 'value'; value: any };
type ReducerAction<E> = ErrorAction<E> | ResetAction | ValueAction;

const defaultState = (defaultValue?: any) => ({
  loading: defaultValue == null,
  value: defaultValue,
  error: null,
});

const reducer =
  <E>() =>
  (state: ReducerState<E>, action: ReducerAction<E>): ReducerState<E> => {
    switch (action.type) {
      case 'error':
        return {
          ...state,
          error: action.error,
          loading: false,
          value: null,
        };
      case 'reset':
        return defaultState(action.defaultValue);
      case 'value':
        return {
          ...state,
          error: null,
          loading: false,
          value: action.value,
        };
      default:
        return state;
    }
  };

const useLoadingValue = <T, E>(defaultValue?: T): LoadingValue<T, E> => {
  const [state, dispatch] = useReducer(
    reducer<E>(),
    defaultState(defaultValue),
  );

  const setError = useCallback((error: E) => {
    dispatch({ type: 'error', error });
  }, []);

  const setValue = useCallback((value?: T) => {
    dispatch({ type: 'value', value });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'reset', defaultValue });
  }, [defaultValue]);

  return useMemo(
    () => ({
      error: state.error,
      loading: state.loading,
      value: state.value,
      setError,
      setValue,
      reset,
    }),
    [state.error, state.loading, reset, setError, setValue, state.value],
  );
};

export default useLoadingValue;
